import React, { useState } from "react";
import { z } from "zod";
import CustomButton from "../../utils/customButton";
import toast, { Toaster } from "react-hot-toast";
import {
  RetailCampaignService,
  useRetailCampaignState,
} from "../../Service/RetailCampaignServices";
import { useAuth } from "../../Context/AuthContext";

const PayoutSchema = z.object({
  enrollee_id: z.string(),
  payout_platform: z.string(),
  payout_id: z.string(),
  retail_campaign_id: z.string(),
});

function Payout(props) {
  const retailCampaignState = useRetailCampaignState();
  const [currentTab, setCurrentTab] = useState(1);
  const [selectedOption, setSelectedOption] = useState("PayPal");
  const [inputValue, setInputValue] = useState("");
  const { currentUser } = useAuth();

  /* DATA PULL */
  /* DATA VALIDATION */
  /* DATA TRANSFORMATION */
  /* CORE LOGIC */
  /* HANDLE INPUTS + FORM VALIDATION */
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };
  /* HANDLE FORM SUBMISSION */

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedOption === "PayPal") {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(inputValue)) {
          toast.error("Please enter a valid email");
          return;
        }
      }

      let rebate_review = {
        enrollee_id: currentUser.uid,
        payout_platform: "paypal", //selectedOption, //we only support PayPal for now
        payout_id: inputValue,
        retail_campaign_id: props.retailCampaignId,
      };
      console.log("rebate review", rebate_review);
      const validatedData = PayoutSchema.safeParse(rebate_review);

      if (!validatedData.success) {
        console.error("Error validating payout data:", validatedData.error);
        toast.error("Error validating payout data");
        return;
      }

      await RetailCampaignService.SubmitRebateForReview(validatedData.data);
      props.setInputValue(inputValue);
      props.setSelectedOption(selectedOption);
      props.setCurrentTab(3);
    } catch (error) {
      console.error("Error submitting payout for review:", error);
      toast.error("Error submitting payout for review");
    }
  };

  /* HANDLE ERRORS */

  return (
    <div className="flex flex-col items-center mx-auto p-6">
      <div className="bg-white p-6 rounded-lg w-full max-w-md mx-auto space-y-6">
        <Toaster />
        {currentTab === 1 && (
          <>
            <div className="text-center">
              <h1 className="text-2xl uppercase font-black text-black">
                Confirm Payout Details
              </h1>
              <p className="text-gray-500 my-10 md:text-0.8 xs:text-0.7">
                Please select which platform you'd like to receive your payout
                through.
              </p>
            </div>
            <div className="flex justify-center space-x-4 my-4">
              <button
                onClick={() => handleOptionSelect("PayPal")}
                className={`flex-1 py-2 rounded-lg text-center ${
                  selectedOption === "PayPal"
                    ? "bg-black text-white"
                    : "bg-gray-200"
                }`}
              >
                PayPal
              </button>
              <button
                onClick={() => handleOptionSelect("Venmo")}
                className={`flex-1 py-2 rounded-lg text-center ${
                  selectedOption === "Venmo"
                    ? "bg-black text-white"
                    : "bg-gray-200"
                }`}
              >
                Venmo
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="relative flex items-center">
                {selectedOption !== "PayPal" && (
                  <span className="absolute left-3 text-gray-500">@</span>
                )}
                <input
                  type="text"
                  className={`w-full ${
                    selectedOption !== "PayPal" ? "pl-8" : "pl-4"
                  } pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none`}
                  value={inputValue}
                  required
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder={
                    selectedOption === "PayPal"
                      ? "Enter PayPal email"
                      : "Enter Venmo username"
                  }
                  pattern={
                    selectedOption === "PayPal"
                      ? "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
                      : undefined
                  }
                  title={
                    selectedOption === "PayPal"
                      ? "Please enter a valid email address"
                      : undefined
                  }
                />
              </div>
              <div className="text-center">
                <CustomButton
                  type="submit"
                  className="hover:bg-indigo-600 text-base text-white font-bold py-2 px-8 rounded-md disabled:opacity-50"
                >
                  Confirm
                </CustomButton>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
}

export default Payout;
