import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomButton from "../../utils/customButton";
import ReceiptCapture from "./ReceiptCapture";
import PayoutConfirmation from "./PayoutConfirmation";
import ReceiptConfirmation from "./ReceiptConfirmation";
import Payout from "./Payout";
import {
  RetailCampaignService,
  useRetailCampaignState,
} from "../../Service/RetailCampaignServices";
import Loader from "../Loader2";
import ReauthenticatePhoneNumber from "./ReauthenticatePhoneNumber";

function CustomerRetailCampaign() {
  const retailCampaignState = useRetailCampaignState();
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [inputValue, setInputValue] = useState("");
  const { campaignId, sourceId, enrolleId } = useParams();
  console.log("campaignId: ", campaignId);
  console.log("sourceId:", sourceId);
  console.log("enrolleId:", enrolleId);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await RetailCampaignService.FetchRetailCampaign(campaignId);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const retailCampaign = retailCampaignState.retailCampaign.value;
  console.log("retail campaign: ", retailCampaign);


  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center flex-col">
          <h2 className="my-5">Loading</h2>
          <Loader />
        </div>
      ) : (
        <>
          <div
            className="flex justify-center"
            style={{ backgroundColor: `#${retailCampaign.theme_color}` }}
          >
            <img
              className="py-5 w-8rem h-8rem rounded"
              src={retailCampaign.brand_photo}
            ></img>
          </div>
          {currentTab === 0 && (
            <ReauthenticatePhoneNumber
              setCurrentTab={setCurrentTab}
              />
          )}
          {currentTab === 1 && (
            <ReceiptCapture
              retailCampaignId={campaignId}
              retailCampaign={retailCampaign}
              setCurrentTab={setCurrentTab}
            />
          )}
          {currentTab === 2 && (
            <Payout
              retailCampaignId={campaignId}
              retailCampaign={retailCampaign}
              setCurrentTab={setCurrentTab}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          )}
          {currentTab === 3 && (
            <PayoutConfirmation
              retailCampaign={retailCampaign}
              setCurrentTab={setCurrentTab}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          )}
        </>
      )}
    </>
  );
}

export default CustomerRetailCampaign;
