import React, { useContext, useState } from "react";
import CampaignTaskCard from "./CampaignTaskCard";
import NewTaskModal from "./NewTaskModal";
import { CampaignState } from "../../../utils/campaignState";
import Loader from "../../Loader";
import { useTabState } from "../../../utils/TabState";
import { CampaignService } from "../../../Service/CampaignServices";
import { BsSearch } from "react-icons/bs";
import { useAuth } from "../../../Context/AuthContext";
import { toast, Toaster } from "react-hot-toast";
import {
  InfluencerService,
  useInfluencerState,
} from "../../../Service/InfluencerService";
import { Mixpanel } from "../../../mixpanel";
import { UsersService, useUserState } from "../../../Service/userServices";
import Multiselect from "multiselect-react-dropdown";
import { Checkbox } from "@material-ui/core";
import { BrandContext } from "../../../routes/PrivateRoute/BrandDashboardRoute";
import CustomButton from "../../../utils/customButton";

function CampaignTasks() {
  const campaignState = CampaignState();
  const [addCampaign, setAddCampaign] = useState();
  const tabState = useTabState();
  const res = JSON.stringify(campaignState.tasks.value);
  const tasks = JSON.parse(res);
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const userState = useUserState();
  const influencerState = useInfluencerState();
  const [numberOfVideos, setNumberOfVideos] = useState({});
  const { brandId } = useContext(BrandContext);

  const handleContinue = async (e) => {
    e.preventDefault();
    let response;
    const task = JSON.stringify(campaignState.tasks.value);

    try {
      const campaign = {
        campaign_name: campaignState.campaign_name.value,
        campaign_photo: campaignState.campaign_photo.value,
        date_created: new Date(),
        influencer_reach_budget: campaignState.credits.value * 100,
        campaign_objective: campaignState.campaign_objective.value,
        utilized_budget: 0,
        number_of_videos: parseInt(campaignState.number_of_videos.value),
        traffic_destination: campaignState.traffic_destination.value,
        target_viewership: campaignState.target_viewership.value,
        total_budget: campaignState.credits.value * 100,
        tasks: JSON.parse(task),
        credits: campaignState.credits.value,
        retail_campaign: campaignState.retail_campaign.value,
      };

      let creator_tasks = JSON.parse(task);
      const totalVideos = campaignState.number_of_videos.value;

      const hasReviews = creator_tasks.some((task) =>
        task.add_ons.some((addon) => addon.name === "Review"),
      );
      const hasRecipes = creator_tasks.some((task) =>
        task.add_ons.some((addon) => addon.name === "Recipes"),
      );
      const hasPhotos = creator_tasks.some((task) =>
        task.add_ons.some((addon) => addon.name === "Photos"),
      );

      console.log("Total number of videos:", totalVideos);

      console.log("CAMPAIGN INFO", campaign);
      campaignState.merge({ loading: true });
      if (brandId)
        response = await CampaignService.AddCampaign(campaign, brandId);

      console.log("response: ", response);
      campaignState.merge({
        loading: false,
        campaign_id: response ? response.campaign_id : "",
      });

      let mixpanel_campaign = {
        campaign_name: campaignState.campaign_name.value,
        creators: totalVideos,
        recipeAvailable: hasRecipes,
        reviewAvailable: hasReviews,
        photoAvailable: hasPhotos,
      };

      Mixpanel.launchCampaign(mixpanel_campaign);
      toast.success("Campaign added successfully!!");
      tabState.merge({ currentCampModalTab: 3 });
    } catch (error) {
      console.log("error: ", error);
      campaignState.merge({ loading: false });
      toast.error(`Error adding campaign: ${error.message}`);
    }
  };

  return (
    <div className="bg-white  rounded-lg lg:w-full ">
      <Toaster />
      {addCampaign ? (
        <NewTaskModal
          onClose={() => setAddCampaign(false)}
          setAddCampaign={setAddCampaign}
        />
      ) : (
        <>
          <div className="flex items-center justify-between">
            <p></p>
            <CustomButton
              className="bg-Blue  text-white px-4 py-2 rounded-lg mr-8"
              onClick={() => setAddCampaign(true)}
            >
              + Add Brief
            </CustomButton>
          </div>
          <div className="bg-white rounded-lg lg:w-full mt-4 overflow-y-visible xs:h-fit">
            {tasks.length === 0 && (
              <p className="text-center flex mt-10 items-center justify-center">
                Briefs will show up here once you have added one.
              </p>
            )}

            <div className="grid lg:grid-cols-3 grid-cols-1 z-0 md:grid-cols-1 h-fit mb-8 gap-2 mx-2">
              {tasks.map((item, i) => {
                return (
                  <>
                    <div key={i}>
                      <CampaignTaskCard
                        campaign={item}
                        id={item.product_id}
                        setAddCampaign={setAddCampaign}
                      />
                    </div>
                  </>
                );
              })}
            </div>
            <div className="mx-5">
              <h1 className="text-2xl text-left font-bold mb-2">
                Campaign Summary
              </h1>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                <div className="bg-gray-100 shadow rounded-lg p-2 text-center">
                  <h3 className="text-base mb-1 font-bold">
                    Number of Influencers
                  </h3>
                  <p className="2xl:text-3xl  mb-2">
                    {campaignState.number_of_videos.value}
                  </p>
                </div>
                <div className="bg-gray-100 shadow rounded-lg p-2 text-center">
                  <h3 className="text-base mb-1 font-bold">
                    Target Viewership
                  </h3>
                  <p className="2xl:text-3xl mb-2">
                    {campaignState.target_viewership.value}
                  </p>
                </div>
                <div className="bg-gray-100 shadow rounded-lg p-2 text-center">
                  <h3 className="text-base mb-1 font-bold">Budget</h3>
                  <p className="2xl:text-3xl mb-2">
                    {Math.round(campaignState.budget.value)}
                  </p>
                </div>
                <div className="bg-gray-100 shadow rounded-lg p-2 text-center">
                  <h3 className="text-base mb-1 font-bold">Number Of Briefs</h3>
                  <p className="2xl:text-3xl mb-2">
                    {Object.keys(campaignState.tasks.value).length}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-4 md:mt-8 mb-2 md:mb-4">
              {tasks.length !== 0 && (
                <CustomButton
                  type="submit"
                  onClick={handleContinue}
                  className={`py-2 bg-red  w-1/2 md:w-1/3 px-4 mb-5 rounded-lg text-white mt-4 justify-center cursor-pointer`}
                >
                  {campaignState.loading.value && <Loader />}
                  Launch Campaign
                </CustomButton>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CampaignTasks;
