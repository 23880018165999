import React, { useState, useEffect, useRef } from 'react';
import ApproveRebateModal from './ApproveRebateModal';
import DenyRebateModal from './DenyRebateModal';
import Loader from '../../Loader2';

function RebatesTable({rebates}) {
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openDenyModal, setOpenDenyModal] = useState(false);
  const [selectedRebate, setSelectedRebate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const modalRef = useRef();

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  const openApprove = (rebate) => {
    setSelectedRebate(rebate);
    setOpenApproveModal(true);
    setModal(true);
  };

  const openDeny = (rebate) => {
    setSelectedRebate(rebate);
    setOpenDenyModal(true);
    setModal(true);
  };

  const closeModal = () => {
    console.log("Closing modal clicked");
    setModal(false);
    setOpenApproveModal(false);
    setOpenDenyModal(false);
    setSelectedRebate(null);
    
  };

  useEffect(() => {
    if (selectedRebate) {
      console.log("Selected rebate:", selectedRebate);
    }
  }, [selectedRebate]);

  return (
    <div className="align-middle inline-block min-w-full overflow-hidden overflow-y-scroll bg-white shadow-dashboard px-8 pt-3 rounded-bl-lg rounded-br-lg">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader />
          <p className="mt-5 text-base">Loading rebates for review...</p>
        </div>
      )}
      {rebates.length === 0 && (
        <div className="flex items-center justify-center py-8">
          <p>No rebates available for review.</p>
        </div>
      )}
      {rebates.length > 0 && (
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">
                Customer ID
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">
                Receipt Photo
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">
                Brand Name
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">
                Product Name
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">
                Rebate Promo
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">
                Date Submitted
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {rebates.map((rebate, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-blue-900">
                  {rebate && rebate.contact && rebate.contact.email}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-blue-900">
                  <a href={rebate.receipt_url} target="_blank" rel="noopener noreferrer">View Receipt</a>
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-blue-900">
                  {rebate.brandName}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-blue-900">
                  {rebate.productName}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-blue-900">
                  {rebate.couponType}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-blue-900">
                  {new Date(rebate.captured_on).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-blue-900">
                  <button
                    className="px-4 py-2 bg-green text-white rounded mr-2"
                    onClick={() => openApprove(rebate)}
                  >
                    Approve
                  </button>
                  <button
                    className="px-4 py-2 bg-Red text-white rounded"
                    onClick={() => openDeny(rebate)}
                  >
                    Deny
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      { modal ?    
        <div 
        className="fixed top-0 left-0 right-0 bottom-0 flex justify-center h-full w-full items-center z-50 bg-black bg-opacity-10"
        onClick={handleClickOutside}>

        {openApproveModal && selectedRebate && (
        <ApproveRebateModal
          rebate={selectedRebate}
          onConfirm={() => {
            closeModal();
          }}
          onClose={closeModal}
        />
      )}
      {openDenyModal && selectedRebate && (
        <DenyRebateModal
          rebate={selectedRebate}
          onConfirm={() => {
            closeModal();
          }}
          onClose={closeModal}
        />
      )}
      </div>: null}
    </div>
  );
}

export default RebatesTable;
