import React, { useState, useEffect, useRef, useContext } from 'react';
import { BiLogOut } from 'react-icons/bi';
import { FiBox, FiCreditCard, FiHome, FiShoppingBag, FiYoutube, FiZap, FiSearch, FiUsers, FiStar } from 'react-icons/fi';
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2";
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../../src/Context/AuthContext';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import { EmailService, useEmailState } from '../../Service/EmailService';
import { MdOutlineRateReview } from "react-icons/md";
import { SubscriptionService, useSubscriptionState } from '../../Service/SubscriptionServices';
import { CreditPackService, useCreditPackState } from '../../Service/CreditPackServices';
import { BrandService, useBrandState } from '../../Service/BrandServices';
import { VscOrganization } from "react-icons/vsc";
import Loader from '../Loader2';
import { AccountService, useAccountState } from '../../Service/AccountServices';
import CustomButton from '../../utils/customButton';
function Sidebar() {
  const [pathname, setPathname] = useState('dashboard');
  const [loading, setLoading] = useState(false);
  const path = window.location.pathname;
  const { logout } = useAuth();
  const { currentUser } = useAuth();
  const history = useHistory();
  const brandState = useBrandState();
  const subsState = useSubscriptionState();
  const accountState = useAccountState();
  const [brandsArr, setBrandsArr] = useState([]);
  const prevBrandsObject = useRef(null);;
  const creditPackState = useCreditPackState();
  const { brandId, setBrandId} = useContext(BrandContext);
  
  useEffect(() => {
    if (brandId !== null) {
      BrandService.FetchSingleBrand(brandId);
      AccountService.FetchAccountInfo(currentUser.uid);
      SubscriptionService.FetchSubscriptionForUser(brandId);
    }
    
  }, [brandState.updateNeed.value, brandId, BrandContext]);
  
  useEffect(() => {
    if (brandId) {
      BrandService.FetchSingleBrand(brandId); 
      CreditPackService.FetchSubscriptionForUser(brandId);
    }
  }, [creditPackState.updateNeed.value, brandId, BrandContext]);
  
  const brand = brandState.brand.value;
  const account = accountState.account.value;
  //console.log("account info:", account);
  const subs = subsState.subscription.value;
  let availUsage = 0
  if (subs) {
    availUsage = subs.paidUsage - subs.currentUsage;
  }
  useEffect(() => {
    if (brand && subs) {
      availUsage = subs.paidUsage - subs.currentUsage;
    }
  }, [subs, subsState.updateNeed.value, creditPackState.updateNeed.value]); 

  let pendingReviews = 0;
  if (brand && brand.reviews) {
    pendingReviews = Object.values(brand.reviews).filter(review => review.completed === false).length;
    //console.log("pendingReviews", pendingReviews);
  }

  useEffect(() => {
    if (account && account.organizations && currentUser.uid !== 'aOLwKHwgMAbQfJv3QIPXk616Nwp1') {
      const orgKey = Object.keys(account.organizations)[0]; // Get the first org key
      const brandsObject = account.organizations[orgKey] && account.organizations[orgKey].brands;
      //console.log("brandsObject", brandsObject)
      if (brandsObject && JSON.stringify(prevBrandsObject.current) !== JSON.stringify(brandsObject)) {
        const newBrandsArr = Object.keys(brandsObject).map(el => ({
          id: el,
          ...brandsObject[el]
        }));
        setBrandsArr(newBrandsArr); // Update brandsArr using setBrandsArr
        prevBrandsObject.current = brandsObject;
      }
    } else if (account && account.brands) {
      const brandsObject = account.brands;
     // console.log("brandsObject", brandsObject)
      if (brandsObject && JSON.stringify(prevBrandsObject.current) !== JSON.stringify(brandsObject)) {
        const newBrandsArr = Object.keys(brandsObject).map(el => ({
          id: el,
          ...brandsObject[el]
        }));
        setBrandsArr(newBrandsArr); // Update brandsArr using setBrandsArr
        prevBrandsObject.current = brandsObject;
      }
    }
  }, [account]);
  //console.log("account", account);
  //console.log("brandsArr", brandsArr);

  let credits = availUsage;

  const handleBrandChange = (e) => {
    const selectedBrandId = e.target.value;
    setLoading(true); // Set loading to true when brand change occurs
    localStorage.setItem('brandId', selectedBrandId); // Update localStorage with the selected brandId
    setBrandId(selectedBrandId); 
    history.push('/brand/dashboard');
   setTimeout(() => {
      // Update the brandId after the 2-second delay  // Set a flag to indicate that the loading screen should be displayed
    setLoading(true);
  
    // Simulate a 2-second delay before switching brands
   
      setLoading(false); // Set loading to false after the 2-second delay
    }, 2000);
  }

  const handleLogOut = () => {
    localStorage.removeItem('brandId');
    localStorage.removeItem('orgId');
    setBrandId('');
    history.push('/brand/login');
    logout();
  };
  useEffect(() => {
    switch (path) {
      case '/brand/dashboard':
        setPathname('dashboard');
        break;
      case '/brand/dashboard/tasks':
        setPathname('tasks');
        break;
      case '/brand/dashboard/campaigns':
        setPathname('campaigns');
        break;
      case '/brand/dashboard/retail':
        setPathname('retail');
      case '/brand/dashboard/requests':
        setPathname('requests');
        break;
      case '/brand/dashboard/products':
        setPathname('products');
        break;
      case '/brand/dashboard/assets':
        setPathname('assets');
        break;
      case '/brand/dashboard/drafts':
        setPathname('drafts');
        break;
      case '/brand/dashboard/billing':
        setPathname('billing');
        break;        
      case '/brand/dashboard/users':
        setPathname('users');
        break;
      case '/brand/dashboard/brief-builder':
        setPathname('brief-builder');
        break;
      case '/brand/creatorlookup':
        setPathname('creator');
        break;
      case '/brand/dashboard/reviews':
        setPathname('reviews');
        break;

      default:
        break;
    }
  }, [path]);
  // console.log("pathname:-", pathname)
  return (
       <>
       {loading && (
        <div className="fixed z-50 inset-0 flex flex-col items-center justify-center bg-white ">
          <div className="text-black font-bold text-center text-3xl">Switching brands...</div>
          <Loader />
        </div>
      )}

      <div className="flex flex-col 2xl:w-64 md:w-48  m-4 sticky text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 rounded-md shadow dark-mode:bg-gray-800 flex-shrink-0"
      style={{ height: 'calc(100vh - 7rem)' }}>
      <nav className="flex-grow md:block md:text-0.6 px-4 pb-4 md:pb-0 md:overflow-y-auto">
      <div className=' border-b-2 border-gray-300'> 
        <div
          className={
            pathname === 'cash-out'
              ? 'block px-4 py-2 mt-2  text-base md:text-0.6 font-semibold  rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 focus:text-white focus:bg-gray-900 focus:text-white focus:shadow-outline'
          } 
          >
            <>
            {credits ? (
              <h3 className='text-center md:text-center text-2xl 2xl:text-4xl font-bold'>
                {credits.toLocaleString()} <span className='text-gray-500 text-sm ml-0.5'>credits</span>
              </h3>
            ) : (
              <h3 className='text-center md:text-center text-3xl 2xl:text-4xl font-bold'>
                0 <span className='text-gray-500 text-sm ml-0.5'>credits</span>
              </h3>
            )}  </>      
          <div className='my-4 text-center items-center justify-center   h-6'>
              <Link className='2xl:py-2 md:py-2 md:px-4 w-full 2xl:px-8 2xl:text-3xl tracking-tightest font-extrabold rounded-lg bg-gray-200 hover:bg-gray-400 hover:text-white'
              to = '/brand/dashboard/billing'>Add Credits</Link>
          </div>
        </div>
      </div>
        {/*<Link
          className={
            pathname === 'dashboard'
              ? 'block px-4 py-2 mt-2 text-sm font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/brand/dashboard">
          <FiHome className="mr-2 mb-1 inline w-8 h-6" />
          Dashboard
        </Link>*/}
    
       {account && (account.organizations || Object.keys(account.brands || {}).length > 1) && (
        <div>
          <select
            // ref={selectRef}
            value={brandId}
            onChange={(e) => handleBrandChange(e)}
            className="mr-2 outline-none bg-gray-100 2xl:text-base md:text-0.6 2xl:p-2 md:p-2 mt-2 rounded-lg w-full"
            required
            placeholder="Choose Brand..."
          >
            {brandsArr.sort((a, b) => a.brand_name.toLowerCase().localeCompare(b.brand_name.toLowerCase())).map((item) => {
              return (
                <option className="2xl:text-base md:text-base text-indigo-800" value={item.id} key={item.id}>
                  {item.brand_name}
                </option>
              );
            })}
          </select>
        </div>
      )}
       
        <Link
          className={
            pathname === 'tasks'
              ? 'block px-4 py-2 mt-2  2xl:text-base text-sm md:text-0.6  font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2  2xl:text-base text-sm md:text-0.6 font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/brand/dashboard/tasks">
          <FiZap className="mr-2 mb-1 inline 2xl:w-8 2xl:h-6" />
          Crafted Studio
        </Link>
        <Link
          className={
            pathname === 'campaigns'
              ? 'block px-4 py-2 mt-2 2xl:text-base text-sm md:text-0.6 font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 2xl:text-base text-sm md:text-0.6 font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/brand/dashboard/campaigns">
          <FiUsers className="mr-2 mb-1 inline 2xl:w-8 2xl:h-6" />
          Crafted Amplify
        </Link>
        {/*<Link
          className={
            pathname === 'retail'
              ? 'block px-4 py-2 mt-2 2xl:text-base text-sm md:text-0.6 font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 2xl:text-base text-sm md:text-0.6 font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/brand/dashboard/retail">
          <FiShoppingBag className="mr-2 mb-1 inline 2xl:w-8 2xl:h-6" />
          Crafted Convert
        </Link>*/}
        <Link
          className={
            pathname === 'reviews'
              ? 'block px-4 py-2 mt-2 2xl:text-base text-sm md:text-0.6 font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 2xl:text-base text-sm md:text-0.6 font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/brand/dashboard/reviews">
          <div className="flex items-center">
            <FiStar className="mb-1 mr-2 inline 2xl:w-8 2xl:h-6" />
            Leave Reviews
            {
              // Only show the badge if there are pending reviews
              (pendingReviews > 0 && pendingReviews < 10) && (
                <span className="inline-block ml-2 bg-red rounded-full px-4 py-0.5 md:px-2 text-base md:text-0.6 font-bold text-white">
                  {pendingReviews}
                </span>
              )
            }
            {
              (pendingReviews > 0 && pendingReviews > 10) && (
                <span className="inline-block ml-2 bg-red rounded-full px-4 py-0.5 md:px-2 text-base md:text-0.6 font-bold text-white">
                  +9
                </span>
              )
            }
          </div>
        </Link>
        {/*<Link
          className={
            pathname === 'brief-builder'
              ? 'block px-4 py-2 mt-2  2xl:text-2xl text-base md:text-0.6 font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2  2xl:text-2xl text-base md:text-0.6 font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/brand/dashboard/brief-builder">
          <HiOutlineWrenchScrewdriver className="mr-2 mb-1 inline 2xl:w-8 2xl:h-6" />
          Brief Builder
        </Link>_*/}
        <Link
          className={
            pathname === 'products'
              ? 'block px-4 py-2 mt-2 2xl:text-base text-sm md:text-0.6 font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2  2xl:text-base text-sm md:text-0.6 font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/brand/dashboard/products">
          <FiShoppingBag className="mr-2 mb-1 inline 2xl:w-8 2xl:h-6" />
          Manage Products
        </Link>
        <Link
          className={
            pathname === 'assets'
              ? 'block px-4 py-2 mt-2 2xl:text-base text-sm md:text-0.6 font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 2xl:text-base text-sm md:text-0.6 font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/brand/dashboard/assets">
          <FiYoutube className="mr-2 mb-1 inline 2xl:w-8 2xl:h-6" />
          Manage Assets
        </Link>
        <Link
          className={
            pathname === 'users'
              ? 'block px-4 py-2 mt-2 2xl:text-base text-sm md:text-0.6 font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 2xl:text-base text-sm md:text-0.6 font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/brand/dashboard/users">
          <VscOrganization className="mr-2 font-thin mb-1 inline 2xl:w-8 2xl:h-6" />
          Manage Users
        </Link>
    
        <Link
          className={
            pathname === 'billing'
              ? 'block px-4 py-2 mt-2 2xl:text-2xl text-base md:text-0.6 font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 2xl:text-2xl text-base md:text-0.6 font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/brand/dashboard/billing">
          <FiCreditCard className="mr-2 mb-1 inline 2xl:w-8 2xl:h-6" />
          Billing
        </Link>

        
      </nav>

      <CustomButton className=" text-white font-bold py-2 px-1 text-lg m-2 rounded-md" onClick={handleLogOut}>
        <BiLogOut className="mr-2 mb-1 inline w-8 h-6" />
        Logout
      </CustomButton>
    </div>
    </>
  );
}

export default Sidebar;