import React, { useState, useEffect, useContext } from "react";
import RetailCampaignCard from "./RetailCampaignCard";
import {
  useRetailCampaignState,
  RetailCampaignService,
} from "../../../Service/RetailCampaignServices";
import NewRetailCampaignModal from "./NewRetailCampaignModal";
import { BrandContext } from "../../../routes/PrivateRoute/BrandDashboardRoute";
import { useAuth } from "../../../Context/AuthContext";
import CustomButton from "../../../utils/customButton";

function RetailCampaigns(props) {
  const retailCampaignState = useRetailCampaignState();
  const [addRetailCampaign, setAddRetailCampaign] = useState(false);
  const { currentUser } = useAuth();
  const { brandId } = useContext(BrandContext);
  const convert = "active";

  useEffect(() => {
    if (brandId) {
      RetailCampaignService.FetchRetailCampaignsForBrand(brandId);
    }
  }, [retailCampaignState.updateNeed.value, brandId]);

  const retail_campaigns = [];
  if (retailCampaignState.retailCampaigns.value) {
    const res = JSON.stringify(retailCampaignState.retailCampaigns.value);
    Object.keys(JSON.parse(res)).forEach((el) => {
      retail_campaigns.push({
        campaignId: el,
        ...JSON.parse(res)[el],
      });
    });
  }

  if (retailCampaignState.error.value) {
    return (
      <div className="bg-white m-4 rounded-lg lg:w-full xs:h-fit">
        <div className="text-red-500 text-center">
          {retailCampaignState.error.value}
        </div>
      </div>
    );
  }

  return (
    <div
      className="bg-white m-4 rounded-lg lg:w-full xs:h-fit"
      style={{ height: "calc(97vh - 5rem)" }}
    >
      <div className="flex items-center h-16 justify-between">
        <h3 className="font-semibold p-8 pl-2 ml-5 2xl:text-2xl md:text-base xs:text-base">
          Crafted Convert
        </h3>
        {convert === "active" ? (
          <CustomButton
            className="text-white 2xl:text-base md:text-base 2xl:px-4 2xl:py-2 md:px-2 md:py-1 rounded-lg mr-8"
            onClick={() => setAddRetailCampaign(true)}
          >
            + New Campaign
          </CustomButton>
        ) : (
          <div className="p-2 relative group w-fit flex items-center">
            <span className="bg-Blue text-white 2xl:text-base md:text-base 2xl:px-4 2xl:py-2 md:px-2 md:py-1 rounded-lg mr-8 opacity-50 cursor-not-allowed">
              + New Campaign
            </span>
            <div className="absolute italic text-sm border z-50 border-gray-400 -top-3 left-5 mb-6 transform -translate-x-1/2 translate-y-full w-64 h-auto bg-gray-200 text-gray-800 py-2 px-4 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
              To add Convert your plan, please contact{" "}
              <span className="font-bold">sarah@usecrafted.com</span>
            </div>
          </div>
        )}
      </div>
      <div>
        <p className="text-gray-500 2xl:text-base md:text-0.7 ml-8">
          {" "}
          After you've created a campaign, click below to review & approve all
          creator proposals here.
        </p>
        <div className="bg-white m-4 rounded-lg lg:w-full 2xl:h-30rem md:h-20rem overflow-y-scroll xs:h-fit">
          <div className="grid lg:grid-cols-5 grid-cols-1 z-0 md:grid-cols-2 gap-2 mx-2">
            {retail_campaigns.length > 0 &&
              retail_campaigns.reverse().map((item, i) => {
                return (
                  <>
                    <div key={i}>
                      <RetailCampaignCard
                        retailCampaign={item}
                        id={item.campaignId}
                      />
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
      <NewRetailCampaignModal
        isOpen={addRetailCampaign}
        closeAction={() => setAddRetailCampaign(false)}
      />
    </div>
  );
}

export default RetailCampaigns;
