import React, { useEffect, useState } from "react";
import { z } from "zod";
import { useParams } from "react-router-dom";
import CustomButton from "../../utils/customButton";
import {
  RetailCampaignService,
  useRetailCampaignState,
} from "../../Service/RetailCampaignServices";
import Loader from "../Loader2";
import { Toaster, toast } from "react-hot-toast";
import { IoMail, IoChatbubble } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import { useAuth } from "../../Context/AuthContext";
import { setUser } from "@sentry/react";
import { EmailService, useEmailState } from "../../Service/EmailService";
import OptInConfirmation from "./OptInConfirmation";
import moment from "moment";
import { IoIosInformationCircleOutline } from "react-icons/io";

function getLuminance(hex) {
  const rgb = parseInt(hex, 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance > 128;
}

function getTextColor(hex) {
  return getLuminance(hex) ? "text-black" : "text-white";
}

const EnrollCustomerSchema = z.object({
  enroll_type: z.string(),
  retail_campaign_id: z.string(),
  source_id: z.string(),
  customer_id: z.string(),
  brand_id: z.string(),
  product_name: z.string(),
  store_locator: z.string(),
  reatiler_name: z.string(),
  contact_details: z
    .object({
      email: z.string().email().optional(),
      phone_number: z.string().optional(),
    })
    .refine((data) => {
      if (!data.email && !data.phone_number) {
        throw new z.ZodError("Either email or phone number is required");
      }
      return data;
    }),
});

function RebateLandingPage(props) {
  const emailState = useEmailState();
  const retailCampaignState = useRetailCampaignState();
  const [currentTab, setCurrentTab] = useState(0);
  const [receiptData, setReceiptData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [activeTab, setActiveTab] = useState("email"); // State to manage the active tab
  const [contactInfo, setContactInfo] = useState("");
  const [phone, setPhone] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const [email, setEmail] = useState("");
  const [confirmationObject, setConfirmationObject] = useState(null);

  const { signupPhone, sendCode, VerifyCode } = useAuth();
  const { campaignId, sourceId } = useParams();
  //console.log("params: ", params);
  //const {influencerId} = useParams();
  const [userId, setUserId] = useState(null);
  //console.log("influencerId:", influencerId);

  /* DATA PULLS */

  useEffect(() => {
    const fetchData = async () => {
      try {
        await RetailCampaignService.FetchRetailCampaign(campaignId);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [campaignId, retailCampaignState.updateNeed.value]);

  const retailCampaign = retailCampaignState.retailCampaign.value;

  /* DATA VALIDATION */

  /* DATA TRANSFORMATIONS */

  /* CORE LOGIC */
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setContactInfo("");
  };

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };

  const themeColor = retailCampaign.theme_color;
  const textColorClass = themeColor ? getTextColor(themeColor) : "text-black";

  /* HANDLE INPUTS + FORM VALIDATION */
  const handleInputChange = (event) => {
    setContactInfo(event.target.value);
  };

  const sendOTP = async () => {
    setSubmitted(true);
    let formatted_phone = "+" + phone;

    try {
      console.log("formatted phone: ", formatted_phone);

      // Attempt to send OTP
      const confirmationResult = await sendCode(formatted_phone);

      setConfirmationObject(confirmationResult);
      setCodeSent(true);
      console.log("OTP sent successfully");
      console.log("confirmationResult: ", confirmationResult);

      // Check if 'confirm' function exists
      if (typeof confirmationResult.confirm === "function") {
        console.log("confirm() exists and is a function.");
      } else {
        console.log("confirm() does not exist or is not a function.");
      }

      // Move to the OTP input tab
      setCurrentTab(1);
      setSubmitted(false);
    } catch (error) {
      console.error("Error sending OTP:", error);

      // Handle specific Firebase errors
      switch (error.code) {
        case "auth/invalid-phone-number":
          toast.error(
            "Invalid phone number. Please check the number and try again.",
          );
          break;
        case "auth/quota-exceeded":
          toast.error("SMS quota exceeded. Please try again later.");
          break;
        case "auth/user-disabled":
          toast.error("User account is disabled.");
          break;
        default:
          toast.error("Failed to send OTP. Please try again.");
      }

      setSubmitted(false);
    }
  };

  const verify = async () => {
    setSubmitted(true);

    try {
      const user = await VerifyCode(confirmationObject, otpCode);

      console.log("Sign in completed");
      console.log("user: ", user);

      setUserId(user.uid);
      setCodeSent(false);
      setUserAuthenticated(true);

      const new_user = {
        uid: user.uid,
        phoneNumber: user.phoneNumber,
      };

      await RetailCampaignService.RegisterCustomer(new_user);

      setSubmitted(false);

      return new_user;
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Error verifying OTP");
      setSubmitted(false);

      throw error;
    }
  };

  /* HANDLE FORM SUBMISSIONS */
  const handleSubmit = async () => {
    try {
      // Validate the phone number
      const user = await verify();

      const data = {
        contact_details: {
          phone_number: `+${phone}`,
          email
        },
        enroll_type: "email",
        retail_campaign_id: campaignId,
        source_id: sourceId,
        customer_id: user.uid,
        brand_id: retailCampaign.brand_id,
        product_name: retailCampaign.product_name,
        store_locator: retailCampaign.retail_store_locator,
        reatiler_name: retailCampaign.retailer_name,
      };

      const validatedData = EnrollCustomerSchema.safeParse(data);

      if (!validatedData.success) {
        console.log(validatedData);
        toast.error("Invalid request, please try again.");
        return;
      }

      await RetailCampaignService.EnrollCustomerIntoCampaign(
        validatedData.data,
      );

      // get response from API to determine if the email sent successfully

      toast.success("Email sent successfully. Check your inbox.");
      handleTabChange(3);
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Error sending email");
    }
  };

  /* ERROR HANDLING */

  if (retailCampaignState.error && retailCampaignState.error.value) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-red-500 text-center">
          {retailCampaignState.error.value}
        </div>
      </div>
    );
  }

  return (
    <div
      className="min-h-screen flex items-center justify-center"
      style={{ backgroundColor: `${themeColor}` }}
    >
      {loading ? (
        <div className="flex items-center justify-center flex-col">
          <h2 className={`my-5 ${textColorClass}`}>Loading</h2>
          <Loader />
        </div>
      ) : (
        <div
          className={`flex flex-col items-center justify-center p-5  rounded-md w-full max-w-sm mx-auto ${textColorClass}`}
        >
          <Toaster />
          <div className="mx-auto">
            <img
              src={retailCampaign.brand_photo}
              className="md:w-2rem md:h-2rem  w-2rem h-2rem rounded-md"
              alt="Brand"
            />
          </div>
          <h1 className="text-4xl font-extrabold text-center mt-5">
            {retailCampaign &&
              retailCampaign.coupon_type === "dollar_off" &&
              `$${retailCampaign.dollar_off} off`}
            {retailCampaign &&
              retailCampaign.coupon_type === "percent_off" &&
              `${retailCampaign.percent_off}% off`}
            {retailCampaign &&
              retailCampaign.coupon_type === "bogo" &&
              `Buy 1, Get 1 Free`}
            {retailCampaign && retailCampaign.coupon_type === "free" && `Free`}
          </h1>
          <p className="text-center mt-2">
            {retailCampaign &&
              retailCampaign.promo_start_date &&
              moment(retailCampaign.promo_start_date).format("MMM DD")}{" "}
            -{" "}
            {retailCampaign &&
              retailCampaign.expiry_date &&
              moment(retailCampaign.expiry_date).format("MMM DD")}
          </p>

          <img
            src={retailCampaign.product_photo}
            className="w-12rem h-12rem mt-5 mx-auto object-cover rounded-md"
            alt="Product"
          />

          <h2 className="text-base font-bold text-center mt-5">Rebate</h2>
          <p className="text-center mt-2 md:text-base xs:text-0.7">
            {retailCampaign &&
              retailCampaign.coupon_type === "dollar_off" &&
              `Get $${retailCampaign.dollar_off} off when you purchase eligible ${retailCampaign.brand_name} products from participating stores.`}
            {retailCampaign &&
              retailCampaign.coupon_type === "percentage_off" &&
              `Get ${retailCampaign.percent_off}% off when you purchase eligible ${retailCampaign.brand_name} products from participating stores.`}
            {retailCampaign &&
              retailCampaign.coupon_type === "bogo" &&
              `Buy 1, Get 1 Free when you purchase 2 eligible ${retailCampaign.brand_name} products from participating stores.`}
            {retailCampaign &&
              retailCampaign.coupon_type === "free" &&
              `Get free eligible ${retailCampaign.brand_name} products from participating stores.`}
          </p>

          {/*<div className="flex justify-around space-x-10 my-4">
              <button
                  className={`flex-1 py-2 px-4 xs:text-xs rounded-lg ${activeTab === "email" ? "bg-gray-600 text-white" : "text-gray-600 border border-gray-600"}`}
                  onClick={() => handleTabClick("email")}
              >
                  Email
              </button>
              <button
                  className={`flex-1 py-2 px-4 xs:text-xs rounded-lg ${activeTab === "text" ? "bg-gray-600 text-white" : "text-gray-600 border border-gray-600"}`}
                  onClick={() => handleTabClick("text")}
              >
                  Text
              </button>
              </div>

              <div className="flex items-center border border-gray-300 rounded p-3 bg-white">
              {activeTab === "email" ? <IoMail className="text-gray-600 mr-2" size={24} /> : <IoChatbubble className="text-gray-600 mr-2" size={24} />}
              <input
                  type={activeTab === "email" ? "email" : "tel"}
                  placeholder={activeTab === "email" ? "Enter your email" : "Enter your phone number"}
                  value={contactInfo}
                  onChange={handleInputChange}
                  className="focus:outline-none w-18rem md:text-base xs:text-0.7 bg-white"
              />
              </div>*/}

          <div className="flex justify-around w-full mt-4">
            <button
              className={`flex-1 py-2 px-4 ${
                currentTab === 0
                  ? "bg-gray-600 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
              onClick={() => handleTabChange(0)}
              disabled={!userAuthenticated && currentTab !== 0}
            >
              Phone
            </button>
            <div id="recaptcha-container"></div>
          </div>

          {/* Tab Content */}
          {currentTab === 0 && (
            <div className="mt-4">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="focus:outline-none w-full md:text-base xs:text-0.7 my-2 bg-white text-black p-3 rounded-md"
              />
              <PhoneInput
                country={"us"}
                onlyCountries={["us", "ca"]}
                value={phone}
                onChange={setPhone}
                placeholder="Enter your phone number"
                className="focus:outline-none w-fit md:text-base xs:text-0.7 bg-white text-black"
              />
              <CustomButton
                onClick={() => sendOTP()}
                className="w-full bg-blue-600 text-white font-bold py-3 px-8 mt-5 rounded-md hover:bg-blue-700 disabled:opacity-50"
                disabled={submitted || phone.length === 0}
              >
                {submitted ? "Sending..." : "Send Code"}
              </CustomButton>
              <div className="relative bg-gray-100 p-4 rounded-lg m-6 shadow-md">
                <IoIosInformationCircleOutline className="absolute top-4 left-2" size={15} />
                <p className="ml-4 text-gray-800 leading-relaxed md:text-0.75 xs:text-0.7">
                  Your <strong>phone number</strong> is used solely for authentication, and we will 
                  never contact you beyond sending a verification code. Your <strong>email</strong> 
                  is used only for communication related to this promotion.
                </p>
              </div>
            </div>
          )}

          {currentTab === 1 && (
            <div className="mt-4">
              <p className="text-center mb-4">
                Enter the verification code we sent to your phone via text.
              </p>
              <input
                type="text"
                placeholder="Enter Code"
                value={otpCode}
                onChange={(e) => setOtpCode(e.target.value)}
                className="focus:outline-none w-full md:text-base xs:text-0.7 bg-white text-black p-3 rounded-md"
                maxLength={6}
              />
              <CustomButton
                onClick={() => handleSubmit()}
                className="w-full bg-blue-600 text-white font-bold py-3 px-8 mt-5 rounded-md hover:bg-blue-700 disabled:opacity-50"
                disabled={submitted || otpCode.length !== 6}
              >
                {submitted ? "Verifying..." : "Verify Code"}
              </CustomButton>
            </div>
          )}

          {currentTab === 3 && <OptInConfirmation />}

          <p className="text-center text-sm mt-5">How It Works</p>
          <p className="text-center text-xs mt-1">
            1. Create an account with your phone number, opt-in with email.
          </p>
          <p className="text-center text-xs mt-1">
            2. Use the link we send to your email to find the product in store.
          </p>
          <p className="text-center text-xs mt-1">
            3. Submit your receipt, ensure that you follow the rebate
            guidelines.
          </p>
          <p className="text-center text-xs mt-1">
            4. Confirm your payout & receive rebate in 3-5 business days.
          </p>
        </div>
      )}
    </div>
  );
}

export default RebateLandingPage;
