import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import GlobalDialog from "../../utils/globalDialog";
import { useAuth } from "../../Context/AuthContext";
import { ProductService, useProductState } from "../../Service/ProductServices";
import {
  AuthService,
  userAuthstate,
} from "../../Service/authenticationService";
import { TaskService } from "../../Service/TaskServices";
import { UsersService, useUserState } from "../../Service/userServices";
import {
  useCampaignState,
  CampaignService,
} from "../../Service/CampaignServices";
import { toast, Toaster } from "react-hot-toast";
import { baseUrl } from "../../baseUrl";
import Multiselect from "multiselect-react-dropdown";
import Collapsible from "react-collapsible";
import VideoCarousel from "./VideoCarousel";
import { CampaignEmailService } from "../../Service/CampaignEmailService";
import { BsChevronCompactDown, BsChevronCompactUp } from "react-icons/bs";
import { Mixpanel } from "../../mixpanel";
import CustomButton from "../../utils/customButton";

function ProposalModal({ open, onClose, id, task, fetchInfluencerTasks }) {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({});
  const [proposals, setProposals] = useState([]);
  const [selectedValue] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const productState = useProductState();
  const userState = userAuthstate();
  const [influencerTasks, setInfluencerTasks] = useState([]);
  const [proposalText, setProposalText] = useState("");
  const user = userState.authUser.value;
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [error, setError] = useState({
    terms: "",
  });

  useEffect(() => {
    if (currentUser.uid) {
      const basicInfo = UsersService.FetchUserInfo(currentUser.uid);

      console.log(basicInfo);
    }
  }, []);

  useEffect(() => {
    if (!currentUser) {
      // Redirect to login or handle user not logged in
    }
  }, [currentUser]);

  const handleAddProposal = (e) => {
    const value = e.target.value;
    setProposalText(value);
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    console.log("task", task);

    if (!proposalText.trim()) {
      alert("Proposal is required.");
      return;
    }
    let err = error;
    if (!isTermsChecked) {
      err.terms = "You must agree to the terms below";
      return setError({ ...error, err });
    }

    setIsSubmitting(true);

    // Create the proposal object with the required fields
    const newProposal = {
      creator_id: currentUser.uid,
      creator_name: user.name,
      creator_address: user.shipping_details ? user.shipping_details : {},
      creator_socials: user.creator_socials ? user.creator_socials : {},
      proposal: proposalText,
      average_rating: user.average_rating || "Not rated",
      campaign_id: task.campaign_id,
      creator_photo: user.avatar,
      task_id: id,
    };

    const data = {
      task_id: id,
      creator_id: currentUser.uid,
      campaign_id: task.campaign_id,
      brand_name: task.brand_name,
      brand_id: task.brand_id,
      proposals: [newProposal], // Use the array with the single proposal object
    };

    const emailData = {
      brand_id: task.brand_id,
      creator_id: currentUser.uid,
      task_id: id,
      proposal: proposalText,
      "Brand ID": task.brand_id,
    };

    const new_task = {
      brand_name: task.brand_name,
      campaign_id: task.campaign_id,
      campaign_name: task.campaign_name,
      id: task.id,
      name: task.name,
    };

    try {
      await CampaignService.SendProposalCampTask(task.campaign_id, data);
      await CampaignEmailService.SendSubmissionEmail(emailData);
      await CampaignEmailService.SendNewProposalEmail(emailData);

      Mixpanel.proposalSubmitted(new_task);
      await fetchInfluencerTasks();

      toast.success(
        (t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white  rounded-lg pointer-events-auto flex `}
            style={{
              padding: "16px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ fontSize: "16px", fontWeight: "bold" }}>
              Proposal sent. You can view the status of your proposal in "Manage
              Tasks".
            </p>
          </div>
        ),
        { duration: 3500 },
      );

      setTimeout(() => {
        onClose();
      }, 4000);
    } catch (error) {
      const { data } = error.response;
      console.error("Error submitting proposal:", error);
      toast.error(data.message ? data.message : "Failed to submit proposal.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <GlobalDialog className="px-3" open={open} onClose={onClose}>
        <form onSubmit={handleConfirm}>
          <Toaster />
          <div className="m-3 bg-white rounded-lg text-3xl dark:bg-gray-700">
            <Collapsible
              trigger={[
                "Brief Details",
                <div className="px-3 absolute right-0">
                  <BsChevronCompactDown />
                </div>,
              ]}
              triggerWhenOpen={[
                "Brief Details",
                <div className="px-3 absolute right-0">
                  <BsChevronCompactUp />
                </div>,
              ]}
              contentOuterClassName="mb-6"
              triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
              triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            >
              <div className="m-3 bg-white rounded-lg text-3xl dark:bg-gray-700">
                <div className="flex ml-12 mb-5">
                  <div className="flex mr-5 font-bold">Brand:</div>
                  <a
                    className="text-blue underline"
                    target="_blank"
                    href={`http://${task && task.brand_website}`}
                    rel="noreferrer"
                  >
                    {task && task.brand_name}
                  </a>
                </div>
                <div className="flex flex-col ml-12  md:text-3xl">
                  <div className="mr-5 flex-col mb-5 font-bold">
                    Product: {task.product_name}
                  </div>
                  <div className="mr-5 flex-col mb-5 font-bold">
                    Product Link:{" "}
                    <span className="font-normal">
                      <a
                        href={task.product_link}
                        target="_blank"
                        className="text-blue underline"
                      >
                        {task.product_link}
                      </a>
                    </span>
                  </div>
                </div>
                <div className="flex ml-12 mb-5">
                  <div className="flex mr-5 font-bold">Brief:</div>
                  <div className="flex flex-col space-y-2">
                    <div>
                      {task && task.number_of_videos > 0 && (
                        <>
                          <div className="flex-wrap">
                            {" "}
                            We invite you to craft a unique video that
                            highlights the brand in a non-salesy way and share
                            it on the platforms specified below (ie, TikTok &
                            Instagram Reels). Submit the link to us afterwards,
                            so we can track and analyze the engagement.
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      Crafted and the brand will have usage rights for these
                      video assets in perpetuity.
                    </div>
                  </div>
                </div>
                <div className="ml-12 mb-5">
                  <div>
                    <h3 className="font-bold">Platform(s): </h3>
                    {task &&
                      task.platform.map((platform, index) => (
                        <span key={index} className="mr-2">
                          {platform}
                          {index < task.platform.length - 1 ? "," : ""}
                        </span>
                      ))}
                    <div className="mt-2 font-bold">
                      {task && task.brand_name} is prioritizing these
                      products/flavors:
                    </div>
                    <div className="">{task && task.product_name}</div>
                  </div>
                  <div>
                    <div className="mt-2 font-bold">
                      {task && task.brand_name}'s Target Audience:
                    </div>
                    <div className="">{task && task.target_audience}</div>
                  </div>
                  <div>
                    <div className="mt-2 font-bold">
                      Key talking points for creators:
                    </div>
                    <div className="break-all">
                      {task && task.talking_points}
                    </div>
                  </div>
                </div>
              </div>
            </Collapsible>

            <Collapsible
              trigger={[
                "Examples of Video Types",
                <div className="px-3 absolute right-0">
                  <BsChevronCompactDown />
                </div>,
              ]}
              triggerWhenOpen={[
                "Examples of Video Types",
                <div className="px-3 absolute right-0">
                  <BsChevronCompactUp />
                </div>,
              ]}
              contentOuterClassName="mb-6"
              triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
              triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            >
              <div className="m-3">
                <VideoCarousel />
              </div>
            </Collapsible>

            <div className="m-3">
              <div className="py-3">
                <div className="font-bold text-center">
                  Please submit video ideas below. Creative and descriptive
                  responses improve your chance of getting selected.
                </div>

                <>
                  <div className="py-3 mb-2">
                    <span className="mb-2">
                      {" "}
                      Share your idea for your video.
                    </span>
                    <textarea
                      type="textarea"
                      rows={5}
                      onChange={handleAddProposal}
                      className="py-3 px-4 text-black flex items-center text-sm font-medium leading-none bg-gray-200 hover:bg-gray-300 cursor-pointer  w-full rounded"
                      required
                    />
                  </div>
                </>

                {/*<div className="font-bold text-center">
                Please submit video ideas below. Creative and descriptive responses improve your
                chance of getting selected.
              </div
              <textarea
                rows={5}
                onChange={(e) => setProposalText(e.target.value)}
                className="py-3 px-4 text-black flex items-center text-sm font-medium leading-none bg-gray-200 hover:bg-gray-300 cursor-pointer  w-full rounded"
                required
              />>*/}
              </div>
            </div>

            <div className="ml-4 items-start">
              {error && error.terms && (
                <div className="ml-1 mb-3 text-sm text-Red">{error.terms}</div>
              )}
              <input
                className="mt-0.5"
                type="checkbox"
                id="isTermsChecked"
                name="isTermsChecked"
                value={isTermsChecked}
                onChange={(e) => setIsTermsChecked(e.target.checked)}
              />
              <label htmlFor="isTermsChecked" className="ml-2 hover:text-black">
                I acknowledge that my rate for this task will be &nbsp;
                <span className="font-bold ">
                  $
                  {Number(
                    ((task.platform.includes("TikTok") &&
                      user &&
                      user.creator_socials &&
                      user.creator_socials.tiktok &&
                      user.creator_socials.tiktok.performance &&
                      user.creator_socials.tiktok.performance.suggestedRate /
                        100) ||
                      0) +
                      ((task.platform.includes("Instagram") &&
                        user &&
                        user.creator_socials &&
                        user.creator_socials.instagram &&
                        user.creator_socials.instagram.suggested_rate / 100) ||
                        0) +
                      75 +
                      (task && task.add_ons
                        ? task.add_ons.reduce(
                            (total, addon) => total + addon.price,
                            0,
                          )
                        : 0),
                  ).toFixed(2)}
                </span>
                &nbsp; and is not subject to change at any point during this
                task.
              </label>
            </div>

            <div className="items-center justify-center text-center">
              <CustomButton
                className=" text-white w-1/3 mt-8 py-4 outline-none text-base"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Confirming..." : "Confirm Proposal"}
              </CustomButton>
            </div>
          </div>
        </form>
      </GlobalDialog>
    </>
  );
}

export default ProposalModal;
