import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import HttpsRedirect from "react-https-redirect";
import "@hookstate/devtools";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [],
});

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <HttpsRedirect>
        <App />
      </HttpsRedirect>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root"),
);
