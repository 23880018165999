import React from "react";
import checkMark from '../../Assets/Eo_circle_green_checkmark.png';

function OptInConfirmation(props) {

  return (
    <div className="flex flex-col items-center p-6">
      <div className="flex justify-center my-10">
        <img src={checkMark} alt="Confirmation Checkmark" className="w-24 h-24"/>
      </div>

      <div className="text-center space-y-4">
        <h1 className="text-2xl font-bold">You have successfully opted into this campaign!</h1>
        <p className="text-gray-500">
          You will receive an email confirmation shortly. If you do not receive it, please check your spam folder and mark it as not spam.
        </p>
        <p className="text-gray-500">
          You can close this tab now.
        </p>
      </div>
    </div>
  );
}

export default OptInConfirmation
