import React from 'react';
import { useTabState } from '../../../utils/TabState';
import CampaignTab from '../../../common/steps/campaign/campaignTab';
import BasicCampaignInfo from './BasicCampaignInfo';
import CampaignDetails from './CampaignSubNav';
import Campaigns from './Campaigns';
function CampaignNavTabs() {

  const tabState = useTabState();

  return (
    <div className="bg-white m-4 rounded-lg lg:w-full xs:h-fit"  style={{ height: 'calc(95vh - 5rem)'}}>
      {/*<CampaignTab currentCampTab={tabState.currentCampTab.value} />*/}
      {tabState.currentCampTab.value === 0 && <Campaigns />}
      {tabState.currentCampTab.value === 1 && <BasicCampaignInfo />}
      {/*tabState.currentCampTab.value === 2 && <NewRetailCampaign />*/}
      {tabState.currentCampTab.value === 2 && <CampaignDetails />}
      
    </div>
  );
}

export default CampaignNavTabs;
