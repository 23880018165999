import React, { useEffect } from 'react';
import { GreetUserService, useGreetingState } from '../Service/GreetServices';
import Loader from './Loader2';

function GreetUser(props) {
    //const { userId } = props;

    const userId = "vdV7LAF77Ogv2rf8u0bbMigxTaC2";

    const greetingState = useGreetingState();

    useEffect(() => {
        if (userId) {
          console.log("userId: ", userId);
          GreetUserService.getGreeting(userId);
          console.log("greetingState: ", greetingState.greeting.get());

        }
      }, [userId]);

    
    return (
    <div>
        {greetingState.loading.value ? ( <div className="bg-white m-4  rounded-lg lg:w-full h-40 "><Loader /></div>) 
        :(
                <div className="bg-white m-4  rounded-lg lg:w-full h-40 ">
                    <h1>{greetingState.greeting.value}</h1>
                </div>

        )}

    </div>
    );
}

export default GreetUser;