import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Route from "./routes";
import * as Sentry from "@sentry/react";


function App() {
  return (
    <Sentry.ErrorBoundary>
      <div>
        <Router>
          <Route />
        </Router>
      </div>
    </Sentry.ErrorBoundary>
  );
}

export default App;
