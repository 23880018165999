import React, { useContext, useEffect, useState } from "react";
import { CampaignState } from "../../../utils/campaignState";
import { useTabState } from "../../../utils/TabState";
import {
  AuthService,
  userAuthstate,
} from "../../../Service/authenticationService";
import { useAuth } from "../../../Context/AuthContext";
import { useHistory } from "react-router-dom";
import { useBrandState, BrandService } from "../../../Service/BrandServices";
import {
  useRetailCampaignState,
  RetailCampaignService,
} from "../../../Service/RetailCampaignServices";
import { Link } from "react-router-dom";
import { BrandContext } from "../../../routes/PrivateRoute/BrandDashboardRoute";
import CustomButton from "../../../utils/customButton";

function RetailCampaignCard(props) {
  const retailCampaignState = useRetailCampaignState();
  const { currentBrand } = useAuth();
  const brandState = useBrandState();
  const { brandId } = useContext(BrandContext);
  const history = useHistory();
  const [status, setStatus] = useState("ongoing");

  /*const viewCampaign = (campaign) => {
    console.log(campaign)
    tabState.merge({ currentCampTab: 2 });
    history.push(`/brand/dashboard/campaigns/${campaign.campaignId}`);
  };*/

  const brand_photo = "https://crafted-react-images.s3.amazonaws.com/apex.jpg";

  return (
    <div>
      {/*<Link to={`/brand/dashboard/campaigns/${props.id}`} className="card-link">*/}
      <div className="bg-lightGray items-center rounded-xl p-1 flex flex-col cursor-pointer">
        <div className="relative">
          <img
            src={brand_photo}
            className="rounded-lg w-full 2xl:h-12rem md:h-8rem object-over"
            alt="img"
          />
          {status === "ongoing" && (
            <div className="absolute inset-0 bg-black opacity-75 rounded-lg flex items-center justify-center">
              <span className="text-white bg-red px-2 py-1 rounded-full text-sm opacity-100">
                Ongoing
              </span>
            </div>
          )}
          <div className="text-lg my-2 mb-1 text-red font-extrabold text-center">
            {/*props.campaign.campaign_credits && props.campaign.campaign_credits + ' credits'*/}
          </div>
          <div className="text-base mb-1 font-bold text-center"></div>
        </div>
        <p className="text-red w-full font-extrabold text-center">
          {/*{totalVid} / {totalApprovedPropNum}/ {totalPropNum} proposals*/}
        </p>
        <div className="2xl:text-base md:text-0.7 text-center mb-1 font-bold truncate w-36">
          Walmart
        </div>
        {/*<div className="items-center justify-center text-center">
                <>
                <CustomButton
                    className=" text-white px-2
                    outline-none text-xs"
                >
                    View Campaign
                </CustomButton>
                </>
            </div>*/}
      </div>
    </div>
  );
}

export default RetailCampaignCard;
