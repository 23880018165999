import React, { useState, useEffect, useContext } from "react";
import "react-responsive-modal/styles.css";
import GlobalDialog from "../../../utils/globalDialog";
import Loader from "../../Loader";
import { useAuth } from "../../../Context/AuthContext";
import {
  AuthService,
  userAuthstate,
} from "../../../Service/authenticationService";
import { useTaskState } from "../../../Service/TaskServices";
import { BrandService, useBrandState } from "../../../Service/BrandServices";
import { SubscriptionService } from "../../../Service/SubscriptionServices";
import { CampaignState } from "../../../utils/campaignState";
import Multiselect from "multiselect-react-dropdown";
import {
  ProductService,
  useProductState,
} from "../../../Service/ProductServices";
import {
  CampaignService,
  useCampaignState,
} from "../../../Service/CampaignServices";
import { toast, Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { BrandContext } from "../../../routes/PrivateRoute/BrandDashboardRoute";
import CustomButton from "../../../utils/customButton";

const styles = {
  option: {
    // To change css for dropdown options
  },
  optionContainer: {
    // To change css for option container
    overflowY: "scroll",
    overflowX: "hidden",
    position: "relative",
  },
  multiselectContainer: {
    // To change css for multiselect (Width,height,etc..)
    height: "20px",
    marginTop: "10px",
    marginBottom: "65px",
    position: "relative",
    outerWidth: "95%",
  },
  chips: {
    // To change css chips(Selected options)
    // display:"none",
    background: "#A9A9A9",
  },
  searchBox: {
    // To change search box element look
    border: "none",
    padding: "7px 10px",
    fontSize: "15px",
    backgroundColor: "#F3F4F6",
    backgroundImage: "url(../../Assets/drop_down.png)",
    borderRadius: "10px",
  },
};

export default function EditCurrentTaskModal(props) {
  const { currentUser } = useAuth();
  const { brandId } = useContext(BrandContext);
  const taskState = useTaskState();
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [recipe, setRecipe] = useState(props.task.number_of_videos);
  const [taskCredits, setTaskCredits] = useState(0);
  const productState = useProductState();
  const brandState = useBrandState();
  const campaignStateData = useCampaignState();

  const platformOptions = [
    { platform: "Instagram" },
    { platform: "TikTok" },
    // ... additional platforms
  ];

  //handle input
  const [taskName, setTaskName] = useState(props.task.name);
  const [isRecipesChecked, setIsRecipesChecked] = useState(false);
  const [isReviewChecked, setIsReviewChecked] = useState(false);
  const [isPhotosChecked, setIsPhotosChecked] = useState(false);
  const [audience, setAudience] = useState(props.task.target_audience);
  const [platforms, setPlatforms] = useState(props.task.platform);
  const [generalTalking, setGeneralTalking] = useState(
    props.task.talking_points,
  );
  const [targetRetailer, setTargetRetailer] = useState(props.task.retailer);
  const [linkLocator, setLinkLocator] = useState(props.task.store_list);
  const [trafficDestination, setTrafficDestination] = useState();
  const [capInstructions, setCapInstructions] = useState();
  const [trafficLink, setTrafficLink] = useState();
  const [specificRegion, setSpecificRegion] = useState(props.task.regions);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [linkRequired, setLinkRequired] = useState(false);
  const [selectedValue] = useState([]);
  const [selectedRec, setSelectedRec] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const onStateSelect = (selectedList) => {
    setSelectedStates(selectedList);
    console.log("Selected states: ", selectedList);
  };

  const onStateRemove = (selectedList) => {
    setSelectedStates(selectedList);
    console.log("Removed states: ", selectedList);
  };

  const fetchData = async () => {
    if (id) {
      setLoading(true);
      const campaignData = await CampaignService.FetchSingleCampaign(id);
      setLoading(false);

      if (campaignData) {
        const tasks =
          Object.keys(campaignData.tasks).length !== 0
            ? Object.values(campaignData.tasks)
            : [];
        // Use the tasks data as needed

        // Assign the tasks to the state or perform any other logic with the tasks data
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    // tabState.merge({ currentTab: 1 });
    if (productState.updateNeed.value) {
      ProductService.FetchProductForUser(brandId);
    }
  }, [productState.updateNeed.value]);

  useEffect(() => {
    if (brandId !== null) {
      BrandService.FetchBrandForUser(brandId);
    }
  }, [brandState.updateNeed.value]);

  const brand = brandState.brand.value;

  useEffect(() => {
    // tabState.merge({ currentTab: 1 });
    if (productState.updateNeed.value) {
      ProductService.FetchProductForUser(brandId);
    }
  }, [productState.updateNeed.value]);

  const res = JSON.stringify(productState.products.value);

  const productTemp = [];
  if (productState.products.value) {
    Object.keys(JSON.parse(res)).forEach((el) => {
      productTemp.push({
        id: el,
        ...JSON.parse(res)[el],
      });
    });
  }
  const handleAddRecipe = () => {
    let rec = recipe;
    let cred = taskCredits;
    setTaskCredits(cred + 2);
    setRecipe(rec + 1);
  };
  const handleDRecipe = () => {
    if (recipe > 0) {
      let rec = recipe;
      let cred = taskCredits;
      setTaskCredits(cred - 2);
      setRecipe(rec - 1);
    }
  };
  const handleRecipeChange = (event) => {
    const value = parseInt(event.target.value);
    if (isNaN(value)) {
      setRecipe(0);
    } else {
      setRecipe(value);
    }
  };
  const authState = userAuthstate();

  useEffect(() => {
    const user = brandId ? brandId : null;
    if (user) {
      AuthService.FetchAuthUser(brandId);
      SubscriptionService.FetchSubscriptionForUser(brandId);
    }
  }, [authState.updateNeed.value]);

  const stateNames = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const stateAbbreviations = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];
  const stateOptions = [
    { label: "United States of America", value: "USA" },
    ...stateNames.map((name, index) => ({
      label: name,
      value: stateAbbreviations[index],
    })),
  ];

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      campaignStateData.merge({ loading: true });
      const product = productTemp.find((obj) => obj.id === selectedProduct);
      const data = {
        brand_id: brandId,
        task_id: props.task.id,
        campaign_id: props.task.campaign_id,
        task: {
          brand_name: brand.brand_name,
          brand_photo:
            brand.brand_avatar ||
            "https://crafted-react-images.s3.amazonaws.com/pexels-chevanon-photography-302899.jpg",
          brand_website: brand.brand_website || "none",
          name: taskName,
          photo: product.thumbnail
            ? product.thumbnail
            : "https://crafted-react-images.s3.amazonaws.com/pexels-chevanon-photography-302899.jpg",
          platform: selectedPlatforms.map((item) => item.platform),
          product_name: product.name ? product.name : "none",
          product_id: selectedProduct,
          product_link: product.link ? product.link : "none",
          product_photo: product.thumbnail ? product.thumbnail : "none",
          product_price: product.price1 ? product.price1 : 1000,
          traffic_link: trafficLink != "" ? trafficLink : null,
          traffic_destination: trafficDestination,
          link_required: linkRequired,
          add_ons: [],
          target_audience: audience,
          talking_points: generalTalking,
          retailer: targetRetailer,
          store_list: linkLocator,
          regions: selectedStates.map((item) => item.value),
        },
      };

      if (isRecipesChecked)
        data.task.add_ons.push({ name: "Recipes", price: 25.0 });
      if (isReviewChecked)
        data.task.add_ons.push({ name: "Review", price: 15.0 });
      if (isPhotosChecked)
        data.task.add_ons.push({ name: "Photos", price: 10.0 });
      CampaignService.EditTask(props.task.id, data);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      toast.success("Task Edited Successfully", { duration: 1500 });
      props.reload();
      props.onClose();
    } catch (error) {
      console.error(error);
      toast.error("Error Editing Task");
    }
  };
  useEffect(() => {
    const initialSelectedPlatforms =
      (props.task && props.task.platform.map((plat) => ({ platform: plat }))) ||
      [];
    setSelectedPlatforms(initialSelectedPlatforms);
  }, [props.task.platform]);

  const onSelect = (selectedList) => {
    setSelectedPlatforms(selectedList);
  };

  const onRemove = (selectedList) => {
    setSelectedPlatforms(selectedList);
  };

  return (
    <GlobalDialog
      className="w-screen"
      open={props.open}
      onClose={props.onClose}
    >
      <Toaster />
      <form onSubmit={handleSubmit} className="px-4">
        <div className="my-2">
          <label htmlFor="note1" className="text-black block md:text-lg mb-2">
            Task Name
          </label>
          <input
            name="note1"
            className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
            placeholder=""
            maxLength={40}
            required
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="description"
            className="text-black block md:text-lg mb-2"
          >
            What add-ons would you like?
          </label>
          <div className="flex rounded-lg">
            <div className="w-2/5 p-2">
              <input
                type="checkbox"
                id="isRecipesChecked"
                name="isRecipesChecked"
                checked={isRecipesChecked}
                onChange={(e) => setIsRecipesChecked(e.target.checked)}
                className="p-3"
              />
              <span className="mx-2">Recipes (+ $50 each) </span>
            </div>
            <div className="w-2/5 p-2">
              <input
                type="checkbox"
                id="isReviewChecked"
                name="isReviewChecked"
                checked={isReviewChecked}
                onChange={(e) => setIsReviewChecked(e.target.checked)}
              />
              <span className="mx-2">Reviews (+ $30 each) </span>
            </div>
            <div className="w-2/5 p-2">
              <input
                type="checkbox"
                id="myCheckbox"
                name="myCheckbox"
                checked={isPhotosChecked}
                onChange={(e) => setIsPhotosChecked(e.target.checked)}
              />
              <span className="mx-2"> Photos (+ $25 each) </span>
            </div>
          </div>
        </div>
        <div className="my-2">
          <label
            htmlFor="audience"
            className="text-black block md:text-lg mb-2"
          >
            What products do you want to showcase?
          </label>
          <select
            // ref={selectRef}
            value={selectedProduct}
            onChange={(e) => setSelectedProduct(e.target.value)}
            className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
            required
            placeholder="Choose Product..."
          >
            <option className="text-sm text-indigo-800" value="">
              Choose Product
            </option>
            {productTemp.map((item) => {
              return (
                <option
                  className="text-sm text-indigo-800"
                  value={item.id}
                  key={item.id}
                >
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="my-2">
          <label
            htmlFor="description"
            className="text-black block md:text-lg mb-2"
          >
            Who is your ideal target audience? Who is the product aimed at?
          </label>
          <input
            name="audience"
            className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
            placeholder=""
            required
            value={audience || ""} // Set a default empty string if audience is undefined or null
            onChange={(e) => setAudience(e.target.value)}
          />
        </div>
        <div className="my-2">
          <label
            htmlFor="generalTalking"
            className="text-black block md:text-lg mb-2"
          >
            General talking points for the videos
          </label>
          <textarea
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
            rows="5"
            cols="50"
            required
            name="generalTalking"
            value={generalTalking}
            onChange={(e) => setGeneralTalking(e.target.value)}
            // ref={generalTalking}
          />
        </div>
        <div className="w-full">
          <div className="flex rounded-lg">
            <div className="w-1/3 p-2">
              <label
                htmlFor="description"
                className="text-black block md:text-lg mb-2"
              >
                Link required?
              </label>
              <select
                name="linkRequired"
                className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                value={linkRequired}
                onChange={(e) => setLinkRequired(e.target.value === "true")}
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>
            <div className="w-2/3 p-2">
              <span
                htmlFor="description"
                className="text-black block md:text-lg mb-2"
              >
                Link for{" "}
                {trafficDestination === "amazon"
                  ? "Amazon Product Page"
                  : trafficDestination === "brand_website"
                  ? "Brand Website"
                  : trafficDestination === "store_locator"
                  ? "Store Locator"
                  : trafficDestination === "retail_website"
                  ? "Retail Website"
                  : trafficDestination === "sms_rebate"
                  ? "SMS Rebate Landing Page"
                  : "Other"}
              </span>
              <input
                name="linkLocator"
                className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                placeholder=""
                required
                value={trafficLink || ""}
                onChange={(e) => setTrafficLink(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="flex rounded-lg">
            <div className="w-1/3 p-2">
              <label
                htmlFor="description"
                className="text-black block md:text-lg mb-2"
              >
                Target Retailer
              </label>
              <input
                name="targetRetailer"
                className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                placeholder=""
                required
                value={targetRetailer}
                onChange={(e) => setTargetRetailer(e.target.value)}
              />
            </div>
            <div className="w-2/3 p-2">
              <label
                htmlFor="description"
                className="text-black block md:text-lg mb-2"
              >
                Link to store locator or store list
              </label>
              <input
                name="linkLocator"
                className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                placeholder=""
                required
                value={linkLocator}
                onChange={(e) => setLinkLocator(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="my-2">
          <label
            htmlFor="stateDropdown"
            className="text-black block md:text-base mb-2 font-bold"
          >
            Do influencers need to be from specific regions for this task?
            Specify here.
          </label>
          <Multiselect
            id="stateSelect"
            name="state"
            options={stateOptions}
            selectedValues={selectedStates}
            onSelect={onStateSelect}
            onRemove={onStateRemove}
            displayValue="label"
            required
            style={styles}
          />
        </div>

        <div className="my-2">
          <label
            htmlFor="description"
            className="text-black block md:text-lg mb-2"
          >
            Influencer platform
          </label>
          <Multiselect
            options={platformOptions}
            selectedValues={selectedPlatforms}
            value={selectedPlatforms}
            showCheckbox={true}
            closeOnSelect={true}
            placeholder="Select Platform"
            style={styles}
            onSelect={onSelect}
            onRemove={onRemove}
            displayValue="platform"
          />
        </div>

        <div className="flex justify-center">
          <CustomButton
            type="submit"
            className="py-2 w-fit px-4 text-white mt-2 items-center justify-center text-center cursor-pointer"
          >
            Edit Brief
          </CustomButton>
        </div>
      </form>
    </GlobalDialog>
  );
}
