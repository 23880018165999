import React, { useRef, useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import Collapsible from "react-collapsible";
import { RecipeService, useRecipeState } from "../../Service/RecipeServices";
import { BsChevronCompactDown, BsChevronCompactUp } from "react-icons/bs";
import { useTaskState } from "../../Service/TaskServices";
import { FaCircle, FaRegCopy } from "react-icons/fa";
import moment from "moment-timezone";
import Loader from "../Loader";
import Loader2 from "../Loader2";
import VideoCarousel from "./VideoCarousel";
import { useAuth } from "../../Context/AuthContext";
import { UsersService, useUserState } from "../../Service/userServices";
import { useCampaignState } from "../../Service/CampaignServices";
import { toast, Toaster } from "react-hot-toast";
import CustomButton from "../../utils/customButton";
import { InfluencerTaskService } from "../../Service/InfluencerTaskService";
import useFileUploader from "../../hooks/useFileUploader";
import useSubmitStatus from "../../hooks/useSubmitStatus";
import FileProgressBar from "../FileProgressBar";
import useS3Uploader from "../../hooks/useS3Uploader";

const bg = {
  overlay: {
    background: "rgba(128, 128, 128, 0.099)",
  },
};

const StringChecker = (text) => {
  const formattedText = text;
  if (formattedText[0].length > 50) {
    formattedText[0] = formattedText[0].substring(0, 50) + "...";
  }
  return formattedText[0];
};
const InfluencerSendTaskDeliverables = (props) => {
  const fileRefs = useRef({});
  const taskState = useTaskState();
  const recipeState = useRecipeState();
  const userState = useUserState();
  const [reviewLink, setReviewLink] = useState("");
  const [caption, setCaption] = useState("");
  const campaignState = useCampaignState();
  const [copied, setCopied] = useState(false);
  const { currentUser } = useAuth();
  const [final, setFinal] = useState(false);

  const res = recipeState.recipes.value;
  const recipe = res
    ? Object.entries(res).map((e) => ({
        [e[0]]: e[1],
      }))
    : [];

  const videoLinks = {};
  const s3ReviewLink = {};
  let products = [];
  const timezone = moment.tz.guess();
  let task_deadline_time = moment(props.task.deadline)
    .tz(timezone)
    .format("MMM Do YYYY");

  if (props.task) {
    products = props.task.products
      ? Object.entries(props.task.products).map((e) => ({
          [e[0]]: e[1],
        }))
      : [];
  }

  useEffect(() => {
    if (recipeState.updateNeed.value) {
      RecipeService.FetchRecipeForUser(currentUser.uid);
    }
  }, [recipeState.updateNeed.value]);

  useEffect(() => {
    if (userState.updateNeed.value) {
      UsersService.FetchBasicInfo(currentUser.uid);
    }
  }, [userState.updateNeed.value]);

  const user = userState.user.value;

  // 1 - set num_videos

  var num_videos = props.task.recipe_video + props.task.product_showcase;
  if (num_videos <= 0) {
    console.error("Error: num_videos must be a positive integer");
    return null;
  }

  // 2 - generate appropriate number of video inputs

  // 3 - handleChange that updates a useState element with true/false for upload

  // 4 - Iterate through each element in array of videos & upload to S3, set the result to another variable

  // 5 - place the object into the object for submit task

  const { retrySubmit, setRetrySubmit, isSubmitting, setIsSubmitting } =
    useSubmitStatus();

  const { uploadFile, isLoading: isS3Loading } = useS3Uploader();

  const {
    files: videos,
    inputRef: videoInputRef,
    progress: videoUploadProgress,
    uploadError: videoUploadError,
    uploadHanlder: uploadVideoHandler,
    handleRetryUpload: handleRetryVideoUpload,
  } = useFileUploader();

  const {
    files: photo,
    inputRef: photoInputRef,
    progress: photoUploadProgress,
    uploadError: photoUploadError,
    uploadHanlder: uploadPhotoHandler,
    handleRetryUpload: handleRetryPhotoUpload,
  } = useFileUploader();

  const {
    files: review,
    inputRef: reviewInputRef,
    progress: reviewUploadProgress,
    uploadError: reviewUploadError,
    uploadHanlder: uploadReviewHandler,
    handleRetryUpload: handleRetryReviewUpload,
  } = useFileUploader();

  const handlePhotoChange = (event) => {
    if (event.target.files.length > 3) {
      // Notify the user that only 3 files can be selected
      alert("You can only select up to 3 files");
      // Clear the selected files
      event.target.value = "";
    } else {
      uploadPhotoHandler(event);
    }
  };

  async function sendDeliverables(event) {
    try {
      setIsSubmitting(true);
      event.preventDefault();
      const s3VideoLinks = {};
      const nonTranscodedVideoLinks = [];
      const s3PhotoLinks = {};
      let dated = moment().format("MMM_DD_YYYY_HHmmss");
      // Upload videos to S3 and store URLs in s3VideoLinks
      console.log("Videos: ", videos);

      for (let i = 0; i < videos.length; i++) {
        let file = videos[i];
        console.log("File #" + i);
        console.log(file);
        file = new Blob([file], { type: file.type });
        file.name = videos[i].name.replace(/\s/g, "_");
        const userName =
          user.name ||
          (user && user.shipping_details && user.shipping_details.fullname) ||
          user.username;
        const brandName = props.task.brand_name; // Replace with your actual brand name
        const newName = `${userName}_${brandName}_${dated}${file.name.substr(
          file.name.lastIndexOf("."),
        )}`.replace(/ /g, "_");

        // Create a new File object with the new name
        file = new File([file], newName, { type: file.type });
        try {
          const data = await uploadFile(file, newName);
          console.log("File String #" + i, data);
          console.log("response: ", data);
          console.log("finished");

          if (file.name.toLowerCase().endsWith(".mov")) {
            // Transcode the file
            // await createJob(data.location);
            nonTranscodedVideoLinks.push(data.Location);
            // Replace the .mov file with the .mp4 file in the videoLinks array
            s3VideoLinks[`video${i + 1}`] = data.Location.replace(
              /\.mov$/i,
              ".mp4",
            ).replace(/\s/g, "_");
          } else {
            s3VideoLinks[`video${i + 1}`] = data.Location.replace(/\s/g, "_");
          }
        } catch (error) {
          console.error(`Failed to upload file: ${file.name}`, error);
        }
      }

      if (photo.length > 0) {
        // Upload photos to S3 and store URLs in s3PhotoLinks
        for (let i = 0; i < photo.length; i++) {
          const file = photo[i];
          const data = await uploadFile(file);
          s3PhotoLinks[`photo${i + 1}`] = data.Location.toString();
        }

        // After uploading all files, check if the number of files exceeds three
        if (photo.length !== 3) {
          // Notify the user that only 3 files can be uploaded
          alert("You can only upload up to 3 files");
          setIsSubmitting(false);
          return; // Exit the function or handle the error accordingly
        }
      }

      if (review.length > 0) {
        for (let i = 0; i < review.length; i++) {
          const file = review[i]; // Use `file` instead of `review_file`
          console.log("File #" + i);
          console.log(file);
          const data = await uploadFile(file);
          console.log("File String #" + i);
          console.log(data.Location);

          s3ReviewLink[`review${i + 1}`] = data.Location.toString();
        }
      }

      const dt = new Date();
      let date = dt.toString();

      // Other data for the request payload
      const payload = {
        recipeIdsNames:
          props.task &&
          props.task.add_ons &&
          props.task.add_ons.some((Addon) => Addon.addon === "Recipes")
            ? recipe
            : {},
        photoLinks: s3PhotoLinks || {},
        videoLinks: s3VideoLinks || {},
        review: s3ReviewLink || {},
        caption: caption,
        task_id: props.task.id,
        creator_name:
          props.task.brand_name +
          "_" +
          ((user && user.name) ||
            (user && user.shipping_details && user.shipping_details.fullname) ||
            (user && user.username)) +
          "_" +
          date.substring(4, 21),
        creator_id: currentUser.uid,
        brand_id: props.task.brand_id,
        campaign_id: props.task.campaign_id,
      };

      const emailData = {
        brand_id: props.task.brand_id,
        creator_id: currentUser.uid,
        task_id: props.task.id,
      };

      const response = await InfluencerTaskService.SendDeliverables(
        props.task.id,
        {
          nonTranscodedVideoLinks,
          compaignServiceDeliverables: payload,
          compaignEmailDeliverables: emailData,
        },
      );

      // Submit the payload to your backend
      // await CampaignService.SendDeliverables(props.task.id, payload);
      // await CampaignEmailService.SendDeliverablesEmail(emailData);
      // await CampaignEmailService.ReceiveDeliverablesEmail(emailData);
      // ...
      // Handle success: show success message, fetch tasks, and close modal
      toast.success(
        response.data.result.message || "Deliverables Submitted Successfully",
        { duration: 3000 },
      );
      props.fetchInfluencerTasks();
      setTimeout(() => {
        setRetrySubmit(false);
        props.onClose();
      }, 3000);
    } catch (error) {
      setRetrySubmit(true);
      toast.error(error.message || "Something went wrong", { duration: 3000 });
      // Handle errors, e.g., show an error message or log the error
    } finally {
      // Reset the submitting state if needed
      setIsSubmitting(false);
    }
  }

  const handleCopy = () => {
    const link = props.task.approved_proposals[currentUser.uid].short_link;
    navigator.clipboard
      .writeText(link)
      .then(() => setCopied(true))
      .catch((error) => console.error("Error copying link: ", error));
  };

  function extractBeforeAndAfterURL(str) {
    const regex = /(.*?)(https?:\/\/[^\s]+)/;
    const match = str.match(regex);

    if (match && match.length >= 3) {
      const beforeURL = match[1];
      const afterURL = str.substring(match[1].length + match[2].length);
      return { beforeURL, afterURL };
    }

    return { beforeURL: str, afterURL: "" };
  }

  const convertingString = () => {
    const regex = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*/i;
    if (props.task && props.task.talking_points) {
      if (regex.test(props.task.talking_points)) {
        let extractedUrl =
          props.task.talking_points.match(/\bhttps?:\/\/\S+/gi);
        let visualLink = StringChecker(extractedUrl);
        const { beforeURL, afterURL } = extractBeforeAndAfterURL(
          props.task.talking_points,
        );
        return (
          <div className="break-words max-w-full">
            {beforeURL}
            <p className="max-w-full text-blue-500">
              <a
                className="text-blue-500 hover:underline cursor-pointer"
                href={props.task.talking_points.match(/\bhttps?:\/\/\S+/gi)[0]}
                target="_blank"
                style={{ color: "blue" }}
              >
                {visualLink}
              </a>
            </p>
            {afterURL}
          </div>
        );
      } else {
        return <div>{props.task.talking_points}</div>;
      }
    }
  };

  const fulfillment =
    props.task.approved_proposals &&
    props.task.approved_proposals[currentUser.uid].fulfillment;
  const fulfillmentType =
    props.task.approved_proposals &&
    props.task.approved_proposals[currentUser.uid].fulfillment &&
    props.task.approved_proposals[currentUser.uid].fulfillment.type;

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        center
        styles={bg}
        classNames={{ modal: "customModal" }}
        closeOnOverlayClick={true}
      >
        <>
          <Toaster />
          <Collapsible
            trigger={[
              "Task Instructions",
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>,
            ]}
            triggerWhenOpen={[
              "Task Instructions",
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>,
            ]}
            open={false}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
          >
            <div className="m-1 bg-white rounded-lg text-3xl dark:bg-gray-700 p-5">
              <div className="flex ml-12 mb-5">
                <div className="flex mr-5 font-bold">Brand:</div>
                <a
                  className="text-blue underline"
                  target="_blank"
                  href={`http://${props.task && props.task.brand_website}`}
                  rel="noreferrer"
                >
                  {props.task && props.task.brand_name}
                </a>
              </div>

              {/*<div className="flex ml-12 mb-5">
                  <div className="flex mr-5 font-bold">Deadline:</div>
                  <div>{props.task && task_deadline_time}</div>
                </div>*/}
              <div className="flex ml-12 mb-5">
                <div className="flex mr-5 font-bold">Brief:</div>
                <div className="flex flex-col space-y-2">
                  <div>
                    {" "}
                    {props.task && (
                      <>
                        <div className="flex-wrap">
                          {" "}
                          We invite you to craft a unique video that highlights
                          the brand in a non-salesy way and share it on the
                          platforms specified below (ie, TikTok & Instagram
                          Reels). Submit the link to us afterwards, so we can
                          track and analyze the engagement.
                        </div>
                        <div className="flex-wrap">
                          {" "}
                          Crafted and the brand will have the rights to the
                          video assets in perpetuity.
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="ml-12 mb-5">
                <div>
                  <div className="mt-2 font-bold">
                    Key talking points for creators:
                  </div>
                  <div className="">{convertingString()}</div>
                </div>
                <div>
                  <div className="mt-2 font-bold">
                    {props.task && props.task.brand_name} is prioritizing these
                    products/flavors:
                  </div>
                  <div className="">
                    {props.task &&
                    props.task.product_name.includes("https://") ? (
                      <a
                        href={props.task.product_name}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {props.task.product_name}
                      </a>
                    ) : (
                      props.task.product_name
                    )}
                  </div>
                </div>
                <div>
                  <div className="mt-2 font-bold">
                    {props.task && props.task.brand_name}'s Target Audience:
                  </div>
                  <div className="">
                    {props.task && props.task.target_audience}
                  </div>
                </div>
                {props.task && props.task.caption_instructions && (
                  <div>
                    <div className="mt-2 font-bold">Caption Instructions:</div>
                    <div className="">{props.task.caption_instructions}</div>
                  </div>
                )}
                {props.task && props.task.link_required && (
                  <>
                    <div className="mt-2 font-bold">
                      <strong className="font-extrabold"> NOTE:</strong> Brand
                      requires creator to include a link in their social media
                      bio.
                    </div>
                    <span className="text-base text-gray-400 font-thin italic ">
                      This will help the brand convert audience into customers.
                      You may also include it as apart of your Beacon, Linktree,
                      etc page. Link must be present for at least 1 month after
                      posting.
                    </span>
                    {props.task && props.task.traffic_destination && (
                      <>
                        <div className="mt-2 font-bold">
                          Link will lead to brand's:
                        </div>
                        <div className="text-justify">
                          <div>
                            {props.task.traffic_destination === "amazon"
                              ? "Amazon Product Page"
                              : props.task.traffic_destination ===
                                "brand_website"
                              ? "Brand Website"
                              : props.task.traffic_destination ===
                                "store_locator"
                              ? "Store Locator"
                              : props.task.traffic_destination ===
                                "retail_website"
                              ? "Retail Website"
                              : props.task.traffic_destination === "sms_rebate"
                              ? "SMS Rebate Landing Page"
                              : "Other"}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </Collapsible>
          <Collapsible
            trigger={[
              "My Proposal + Sample Products",
              <div className="px-3 flex flex-row gap-4 absolute right-0">
                {props.currentUserHasFulfillment && (
                  <FaCircle className="text-red" />
                )}
                <BsChevronCompactDown />
              </div>,
            ]}
            triggerWhenOpen={[
              "My Proposal + Sample Products",
              <div className="px-3 flex flex-row gap-4 absolute right-0">
                {props.currentUserHasFulfillment && (
                  <FaCircle className="text-red" />
                )}
                <BsChevronCompactUp />
              </div>,
            ]}
            open={false}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
          >
            <div className="m-3 bg-white rounded-lg text-3xl dark:bg-gray-700">
              {/*<div className="flex mb-5">
                  <div className="flex mr-5 font-bold">Deadline:</div>
                  <div>{props.task && task_deadline_time}</div>
            </div>*/}
              <div className="flex flex-col mb-5">
                <div className="pb-1 font-bold">My Proposal: </div>
                <div className="p-3"></div>
              </div>
              <div className="flex mb-5">
                <div className="flex mr-5 font-bold">Products:</div>
                <div>{props.task.product_name}</div>
                {fulfillment && fulfillmentType == "shipped" && (
                  <ul>
                    <li> &nbsp; will be shipped to your address.</li>
                    <li>
                      <a
                        className="text-red font-bold"
                        href={`https://parcelsapp.com/en/tracking/${fulfillment.tracking_number}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        Track Your Package
                      </a>
                    </li>
                  </ul>
                )}
                {fulfillment && fulfillmentType == "store" && (
                  <ul>
                    <li>
                      You will be purchasing your product in stores, please
                      refer to this store list (see below) to find the location
                      nearest you.
                    </li>
                    <li>
                      The brand will be issuing you a credit for $
                      {(fulfillment.product_price / 100).toFixed(2)} to
                      reimburse upon completion of the task.
                    </li>
                    <li>Retailer:{fulfillment.retailer}</li>
                    <li>
                      <a
                        className="text-red font-bold"
                        href={`${
                          fulfillment.store_link && fulfillment.store_link
                        }`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        Find Nearest Store Location
                      </a>
                    </li>
                  </ul>
                )}
                {fulfillment && fulfillmentType == "promo" && (
                  <ul>
                    <li>
                      You will be purchasing your product online with a promo
                      code, please find the link to buy the product and the
                      promo code below.
                    </li>
                    <li>Promo Code: {fulfillment.promo_code}</li>
                    <li>
                      <a
                        className="text-red font-bold"
                        href={`${
                          fulfillment.product_link && fulfillment.product_link
                        }`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        Buy Product Here
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </Collapsible>
          <Collapsible
            trigger={[
              "Examples of Video Types",
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>,
            ]}
            triggerWhenOpen={[
              "Examples of Video Types",
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>,
            ]}
            open={false}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
          >
            <div className="m-3">
              <VideoCarousel />
            </div>
          </Collapsible>
          <Collapsible
            trigger={[
              "Send Deliverables",
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>,
            ]}
            triggerWhenOpen={[
              "Send Deliverables",
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>,
            ]}
            open={true}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-darkBlack bg-lightGray relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
          >
            <form onSubmit={sendDeliverables}>
              {" "}
              <div className="flex flex-col my-5">
                <div className="flex flex-col space-y-2 mb-3">
                  <div className="font-bold">Checklist before Submission:</div>
                  <div className="list-disc px-4">
                    <li>
                      Shot with natural lighting or good overhead kitchen lights
                    </li>
                    <li>Video quality is clear and not grainy</li>
                    <li>
                      Voiceover is highly energetic, genuine and includes
                      personal anecdotes
                    </li>
                    <li>
                      Correct product benefits stated, key talking points
                      covered, prepared product correctly
                    </li>
                    <li>
                      Video falls into one the video types above and executed
                      perfectly
                    </li>
                    <li>
                      {" "}
                      Submission is the final video, not something that is
                      labeled as #draft or is a screen recording from Capcut.
                    </li>
                  </div>
                </div>

                <div className="font-bold text text-base mr-2">
                  Please upload your deliverables below. You can upload multiple
                  files at once.
                  {props.task && props.task.caption_instructions && (
                    <div>
                      <label htmlFor="caption">Video Caption:</label>
                      <textarea
                        value={caption}
                        onChange={(e) => setCaption(e.target.value)}
                        className="mt-4 mb-4 mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                        id="caption"
                        required
                        placeholder="Add the caption for your video."
                        name="caption"
                        rows="4"
                        cols="50"
                      ></textarea>
                    </div>
                  )}
                  {props.task &&
                    props.task.approved_proposals &&
                    props.task.approved_proposals[currentUser.uid] &&
                    props.task.approved_proposals[currentUser.uid]
                      .short_link && (
                      <div className="text-gray-500 my-2 font-thin">
                        <span className="font-bold text-gray-800 underline">
                          Reminder
                        </span>
                        : This is the link that is required to be put in your
                        bio for 30 days once you have successfully uploaded the
                        post(s).
                        <div className="flex items-center">
                          <a
                            href={
                              props.task.approved_proposals[currentUser.uid]
                                .short_link
                            }
                            className="font-bold text-blue underline"
                            target="_blank"
                          >
                            {
                              props.task.approved_proposals[currentUser.uid]
                                .short_link
                            }
                          </a>
                          <button onClick={handleCopy} className="ml-2">
                            <FaRegCopy title="Copy link" />
                          </button>
                          {copied && (
                            <span className="ml-2 text-green-500">
                              Link copied!
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  {props.task &&
                    props.task.add_ons &&
                    props.task.add_ons.some(
                      (addon) => addon.name === "Recipes",
                    ) && (
                      <div>
                        <p>Add Recipe:</p>
                        <select
                          required
                          className="mt-4 mb-4 mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                        >
                          <option value="" className="text-gray-400">
                            Select a recipe
                          </option>
                          {recipe.length > 0 &&
                            recipe.map((item) => {
                              const res = item[Object.keys(item)[0]];
                              const id = Object.keys(item)[0];
                              return (
                                <option key={id} value={id}>
                                  {res.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    )}
                  {props.task &&
                    props.task.add_ons &&
                    props.task.add_ons.some(
                      (addon) => addon.name === "Review",
                    ) && (
                      <div>
                        <h3 className="font-bold flex items-center gap-3">
                          Screenshot of Product Review:{" "}
                          <FileProgressBar progress={reviewUploadProgress} />
                          {reviewUploadError ? (
                            <div className="flex gap-3 items-center">
                              <span className="text-xs text-danger">
                                {reviewUploadError}
                              </span>
                              <span
                                className="text-white px-2 mr-3 rounded-lg outline-none text-xs bg-Blue cursor-pointer"
                                onClick={handleRetryReviewUpload}
                              >
                                Retry
                              </span>
                            </div>
                          ) : null}
                        </h3>
                        <input
                          id="screenshotInput"
                          type="file"
                          required
                          accept="image/png, image/jpeg"
                          className="focus:outline-none w-full bg-lightGray p-2 mt-2 mr-2 rounded"
                          onChange={(e) => uploadReviewHandler(e)}
                          ref={reviewInputRef}
                        />
                      </div>
                    )}
                  {props.task &&
                    props.task.add_ons &&
                    props.task.add_ons.some(
                      (addon) => addon.name === "Photos",
                    ) && (
                      <div>
                        <h3 className="flex items-center gap-3">
                          Add three Product Photos:{" "}
                          <FileProgressBar progress={photoUploadProgress} />
                          {photoUploadError ? (
                            <div className="flex gap-3 items-center">
                              <span className="text-xs text-danger">
                                {photoUploadError}
                              </span>
                              <span
                                className="text-white px-2 mr-3 rounded-lg outline-none text-xs bg-Blue cursor-pointer"
                                onClick={handleRetryPhotoUpload}
                              >
                                Retry
                              </span>
                            </div>
                          ) : null}
                        </h3>
                        <input
                          id="imageInput"
                          type="file"
                          required
                          accept="image/png, image/jpeg, image/jpg"
                          className="focus:outline-none w-full mb-2 bg-lightGray p-2 mt-2 mr-2 rounded"
                          multiple
                          max="3"
                          ref={photoInputRef}
                          onChange={(e) => handlePhotoChange(e)}
                        />
                      </div>
                    )}
                  <p className="text-xs font-light">
                    Only accepts png, jpeg. 2GB limit{" "}
                  </p>
                  <h3 className="flex items-center gap-3">
                    Add Video:{" "}
                    <FileProgressBar progress={videoUploadProgress} />
                    {videoUploadError ? (
                      <div className="flex gap-3 items-center">
                        <span className="text-xs text-danger">
                          {videoUploadError}
                        </span>
                        <span
                          className="text-white px-2 mr-3 rounded-lg outline-none text-xs bg-Blue cursor-pointer"
                          onClick={handleRetryVideoUpload}
                        >
                          Retry
                        </span>
                      </div>
                    ) : null}
                  </h3>
                  <input
                    id="videoInput"
                    type="file"
                    required
                    accept="video/mp4, video/mov, video/avi"
                    className="focus:outline-none w-full bg-lightGray p-2 mt-2 mr-2 rounded"
                    multiple
                    onChange={(e) => uploadVideoHandler(e)}
                    ref={videoInputRef}
                  />
                  <p className="text-xs font-light">
                    Only accepts mp4, avi, mov. 2GB limit{" "}
                  </p>
                </div>
              </div>
              <div className="my-2">
                <label className="inline-flex items-center mr-4">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-gray-600"
                    checked={final}
                    required
                    onChange={() => setFinal(!final)}
                  />
                  <span className="ml-2 text-base xs:text-0.7 w-full text-gray-700">
                    {" "}
                    I am submitting a video file that can be used as a final
                    deliverable - not a screen recording of the video from
                    editing app or a video watermarked with #draft, etc.{" "}
                  </span>
                </label>
              </div>
              <div className="items-center  my-5 justify-center text-center">
                <CustomButton
                  className=" text-white w-1/3 mt-8 py-4 outline-none text-base"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <span>
                      <Loader />
                      <span className="mr-2">
                        {retrySubmit ? "Retrying" : "Submitting"}...
                      </span>
                    </span>
                  ) : retrySubmit ? (
                    "Retry Submit Deliverables"
                  ) : (
                    "Submit Deliverables"
                  )}
                </CustomButton>
              </div>
            </form>
          </Collapsible>
        </>
      </Modal>
    </div>
  );
};
export default InfluencerSendTaskDeliverables;
