import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  RetailCampaignService,
  useRetailCampaignState,
} from "../../../Service/RetailCampaignServices";
import RebatesTable from "./RebatesTable";

function ReviewRebates() {
  const history = useHistory();
  const retailCampaignState = useRetailCampaignState();
  const pendingRebates = retailCampaignState.rebates.value || [];
  let rebates = [];

  useEffect(() => {
    RetailCampaignService.FetchPendingRebates();
  }, [retailCampaignState.updateNeed.value]);

  if (pendingRebates !== undefined) {
    rebates = Array.isArray(pendingRebates) && Array.from(pendingRebates);
    console.log("Converted rebates:", rebates);
  }

  console.log("pending rebates: ", pendingRebates);

  if (retailCampaignState.error.value) {
    return (
      <div className="bg-white m-4 rounded-lg w-full overflow-scroll p-6">
        <div className="text-red-500 text-center">
          {retailCampaignState.error.value}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white m-4 rounded-lg w-full overflow-scroll p-6">
      <h2 className="font-semibold py-2 text-2xl">Review Rebates</h2>
      {rebates && rebates.length > 0 && <RebatesTable rebates={rebates} />}
    </div>
  );
}

export default ReviewRebates;
