import { createState, useState } from "@hookstate/core";
import axios from "axios";
import { baseUrl } from "../baseUrl";
import { store, useDispatch } from "../Store";
import { getRandomValues } from "crypto";
import * as Sentry from "@sentry/browser";

//State
const state = createState({
  retailCampaign: [],
  retailCampaigns: [],
  rebates: [],
  retrieving: false,
  fetched: false,
  loading: true,
  updateNeed: true,
  error: "",
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case "RETAIL_CAMPAIGN_CREATED":
        return s.merge({
          retailCampaign: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
        });

      case "RETAIL_CAMPAIGN_LOADED":
        return s.merge({
          retailCampaign: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
        });

      case "RETAIL_CAMPAIGNS_LOADED":
        return s.merge({
          retailCampaigns: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
        });

      case "BRAND_RETAIL_CAMPAIGNS_LOADED":
        return s.merge({
          retailCampaigns: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
        });

      case "PENDING_REBATES_LOADED":
        return s.merge({
          rebates: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
        });

      case "SEND_RETAIL_ENROLLMENT_EMAIL":
        return s.merge({
          retailCampaign: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
        });

      case "RETAIL_CAMPAIGN_AMPLIFY":
        return s.merge({
          retailCampaign: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
        });

      case "ENROLL_CUSTOMER_INTO_CAMPAIGN":
        return s.merge({
          // retailCampaign: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
        });

      case "REGISTER_CUSTOMER":
        return s.merge({
          //retailCampaign: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
        });

      case "UPLOAD_RECEIPT":
        return s.merge({
          //retailCampaign: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
        });

      case "SUBMIT_REBATE_FOR_REVIEW":
        return s.merge({
          //retailCampaign: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
        });

      case "APPROVE_REBATE":
        return s.merge({
          //retailCampaign: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
        });

      case "DENY_REBATE":
        return s.merge({
          //retailCampaign: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
        });

      case "SET_LOADING":
        return s.merge({
          loading: action.data,
          error: "",
        });

      case "SET_ERROR":
        return s.merge({
          error: action.data,
          loading: false,
        });

      default:
        return null;
    }
  }, action.type);
});

export const accessRetailCampaignState = () => state;

export const useRetailCampaignState = () => useState(state);

//Service
export const RetailCampaignService = {
  AddRetailCampaign: (data) => {
    const dispatch = useDispatch();
    dispatch(RetailCampaignAction.setLoading(true));

    axios
      .post(`${baseUrl}api/retail/createRetailCampaign`, data)
      .then((res) => {
        const result = res.data;
        dispatch(RetailCampaignAction.createRetailCampaign(result));
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(RetailCampaignAction.setError(err));
      });
  },

  RegisterCustomer: async (data) => {
    const dispatch = useDispatch();
    dispatch(RetailCampaignAction.setLoading(true));

    await axios
      .post(`${baseUrl}api/retail/registerCustomer`, data)
      .then((res) => {
        const result = res.data;
        dispatch(RetailCampaignAction.registerCustomer(result));
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(RetailCampaignAction.setError(err));
      });
  },

  EnrollCustomerIntoCampaign: async (data) => {
    const dispatch = useDispatch();
    dispatch(RetailCampaignAction.setLoading(true));

    return await axios
      .post(`${baseUrl}api/retail/enrollCustomerIntoCampaign`, data)
      .then((res) => {
        const result = res.data;
        dispatch(RetailCampaignAction.enrollCustomerIntoCampaign(result));
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(RetailCampaignAction.setError(err));
        throw err;
      });
  },

  FetchRetailCampaign: async (id) => {
    const dispatch = useDispatch();
    dispatch(RetailCampaignAction.setLoading(true));

    try {
      const { data } = await axios.get(
        `${baseUrl}api/retail/getRetailCampaign/${id}`,
      );
      dispatch(RetailCampaignAction.getRetailCampaign(data.result));

      return data;
    } catch (err) {
      Sentry.captureException(err);
      dispatch(RetailCampaignAction.setError(err));
      throw err;
    }
  },

  FetchAllRetailCampaigns: () => {
    const dispatch = useDispatch();
    dispatch(RetailCampaignAction.setLoading(true));

    axios
      .get(`${baseUrl}api/retail/getAllRetailCampaign}`)
      .then((res) => {
        const result = res.data.result;
        dispatch(RetailCampaignAction.getAllRetailCampaigns(result));
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(RetailCampaignAction.setError(err));
      });
  },

  UploadReceipt: async (data) => {
    const dispatch = useDispatch();
    dispatch(RetailCampaignAction.setLoading(true));
    await axios
      .post(`${baseUrl}api/retail/uploadReceipt`, data)
      .then((res) => {
        const result = res.data;
        dispatch(RetailCampaignAction.uploadReceipt(result));
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(RetailCampaignAction.setError(err));
      });
  },

  SendRetailEnrollmentEmail: (data) => {
    const dispatch = useDispatch();
    dispatch(RetailCampaignAction.setLoading(true));

    axios
      .post(`${baseUrl}api/email/sendRetailEnrollmentEmail`, data)
      .then((res) => {
        const result = res.data;
        dispatch(RetailCampaignAction.sendRetailEnrollmentEmail(result));
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(RetailCampaignAction.setError(err));
      });
  },

  SubmitRebateForReview: async (payload) => {
    const dispatch = useDispatch();
    dispatch(RetailCampaignAction.setLoading(true));

    try {
      const { data } = await axios.post(
        `${baseUrl}api/retail/submitRebateForReview`,
        payload,
      );
      dispatch(RetailCampaignAction.submitRebateForReview(data));
    } catch (err) {
      Sentry.captureException(err);
      dispatch(RetailCampaignAction.setError(err));
      throw err;
    }
  },

  FetchRetailCampaignForAmplify: (id) => {
    const dispatch = useDispatch();
    dispatch(RetailCampaignAction.setLoading(true));

    axios
      .get(`${baseUrl}api/retail/getAmplifyCampaign/${id}`)
      .then((res) => {
        dispatch(
          RetailCampaignAction.getRetailCampaignForAmplify(res.data.result),
        );
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(RetailCampaignAction.setError(err));
      });
  },

  FetchRetailCampaignsForBrand: (id) => {
    const dispatch = useDispatch();
    dispatch(RetailCampaignAction.setLoading(true));

    axios
      .get(`${baseUrl}api/retail/getRetailCampaignsForBrand/${id}`)
      .then((res) => {
        dispatch(
          RetailCampaignAction.getRetailCampaignsForBrand(res.data.result),
        );
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(RetailCampaignAction.setError(err));
      });
  },

  FetchAllPayouts: () => {
    const dispatch = useDispatch();
    dispatch(RetailCampaignAction.setLoading(true));

    axios
      .get(`${baseUrl}api/retail/getAllPayouts`)
      .then((res) => {
        dispatch(RetailCampaignAction.getAllPayoutsForReview(res.data.result));
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(RetailCampaignAction.setError(err));
      });
  },

  FetchPendingRebates: async () => {
    const dispatch = useDispatch();
    dispatch(RetailCampaignAction.setLoading(true));

    axios
      .get(`${baseUrl}api/retail/getPendingRebates`)
      .then((res) => {
        dispatch(RetailCampaignAction.getPendingRebates(res.data.data));
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(RetailCampaignAction.setError(err));
      });
  },

  ApproveRebate: async (data) => {
    const dispatch = useDispatch();
    dispatch(RetailCampaignAction.setLoading(true));

    axios
      .post(`${baseUrl}api/retail/approveRebate`, data)
      .then((res) => {
        dispatch(RetailCampaignAction.approveRebate(res.data.result));
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(RetailCampaignAction.setError(err));
      });
  },

  DenyRebate: (data) => {
    const dispatch = useDispatch();
    dispatch(RetailCampaignAction.setLoading(true));

    axios
      .post(`${baseUrl}api/retail/denyRebate`, data)
      .then((res) => {
        dispatch(RetailCampaignAction.denyRebate(res.data.result));
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(RetailCampaignAction.setError(err));
      });
  },
};

//Action
export const RetailCampaignAction = {
  createRetailCampaign: (data) => {
    return {
      type: "RETAIL_CAMPAIGN_CREATED",
      data: data,
    };
  },

  getRetailCampaign: (data) => {
    return {
      type: "RETAIL_CAMPAIGN_LOADED",
      data: data,
    };
  },

  getAllRetailCampaigns: (data) => {
    return {
      type: "RETAIL_CAMPAIGNS_LOADED",
      data: data,
    };
  },

  getAllPayoutsForReview: (data) => {
    return {
      type: "ALL_PAYOUTS_LOADED",
      data: data,
    };
  },

  sendRetailEnrollmentEmail: (data) => {
    return {
      type: "SEND_RETAIL_ENROLLMENT_EMAIL",
      data: data,
    };
  },

  getRetailCampaignForAmplify: (data) => {
    return {
      type: "RETAIL_CAMPAIGN_AMPLIFY",
      data: data,
    };
  },

  getRetailCampaignsForBrand: (data) => {
    return {
      type: "BRAND_RETAIL_CAMPAIGNS_LOADED",
      data: data,
    };
  },

  enrollCustomerIntoCampaign: (data) => {
    return {
      type: "ENROLL_CUSTOMER_INTO_CAMPAIGN",
      data: data,
    };
  },

  registerCustomer: (data) => {
    return {
      type: "REGISTER_CUSTOMER",
      data: data,
    };
  },

  uploadReceipt: (data) => {
    return {
      type: "UPLOAD_RECEIPT",
      data: data,
    };
  },

  submitRebateForReview: (data) => {
    return {
      type: "SUBMIT_REBATE_FOR_REVIEW",
      data: data,
    };
  },

  getPendingRebates: (data) => {
    return {
      type: "PENDING_REBATES_LOADED",
      data: data,
    };
  },

  approveRebate: (data) => {
    return {
      type: "APPROVE_REBATE",
      data: data,
    };
  },

  denyRebate: (data) => {
    return {
      type: "DENY_REBATE",
      data: data,
    };
  },

  setLoading: (data) => {
    return {
      type: "SET_LOADING",
      data: data,
    };
  },

  setError: (data) => {
    return {
      type: "SET_ERROR",
      data: data,
    };
  },
};
