import axios from 'axios';
import { createState, useState } from '@hookstate/core';
import { store, useDispatch } from '../Store'; // Assuming you have a Store.js file to manage state
import { baseUrl } from '../baseUrl'; // Assuming baseUrl is defined somewhere

// State
const state = createState({
  greeting: '',
  error: null,
  loading: false
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'SET_GREETING':
        return s.merge({ 
            greeting: action.data, 
            loading: false, 
            error: null 
        });

      case 'SET_GREETING_ERROR':
        return s.merge({ 
            error: action.error, 
            loading: false 
        });

      case 'SET_LOADING':
        return s.merge({ 
            loading: true 
        });

      default:
        return null;
    }
  }, action.type);
});

export const accessGreetingState = () => state;
export const useGreetingState = () => useState(state);

// Service
export const GreetUserService = {
  getGreeting: async (userId) => {
    const dispatch = useDispatch();
    dispatch({ type: 'SET_LOADING' });

    console.log("userId - inside Services.js: ", userId);

    await axios
      .post(`${baseUrl}api/greetings/greetUser/${userId}`)
      .then((res) => {
        console.log("res.data.message: ", res.data.message);
        dispatch(GreetUserActions.setGreeting(res.data.message));
      })
      .catch((err) => {
        dispatch(GreetUserActions.setGreetingError('Failed to fetch greeting.'));
      });
  }
};

// Actions
export const GreetUserActions = {
  setGreeting : (data) => {
    return {
        type: 'SET_GREETING',
        data: data
    }
  },
  setGreetingError : (error) => {
    return {
        type: 'SET_GREETING_ERROR',
        error: error
    }
  }
};
