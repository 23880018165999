import React, { useRef, useState, useEffect, useContext } from "react";
import { validateFormFields } from "../../../utils/functions";
import FileUpload from "../../FileUpload";
import Loader from "../../Loader";
import { useAuth } from "../../../Context/AuthContext";
import CurrencyInput from "react-currency-input";
import { useBrandState, BrandService } from "../../../Service/BrandServices";
import {
  useProductState,
  ProductService,
} from "../../../Service/ProductServices";
import { BrandContext } from "../../../routes/PrivateRoute/BrandDashboardRoute";
import Switch from "react-switch";
import { Mixpanel } from "../../../mixpanel";
import CustomButton from "../../../utils/customButton";
import useS3Uploader from "../../../hooks/useS3Uploader";

const fieldErrors = {
  name: "Please Enter Name Carefully",
  image: "Please Select Image",
  retailPrice: "Please Enter  Retail Price",
  discountedPrice: "Please Enter Discounted Price",
  description: "Please Enter Description Price",
  leadTime: "Please Enter Lead Time",
};

const NewProductOnboarding = (props) => {
  const { open, onClose } = props;
  const { currentUser, currentBrand } = useAuth();
  const productState = useProductState();
  const name = useRef("");
  const { brandId } = useContext(BrandContext);
  const description = useRef("");
  const image = useRef("");
  const [errors, setErrors] = useState({});
  const [productImage, setProductImage] = useState(null);
  const [productData, setProductData] = useState({
    retailPrice: "$0.00",
    discountedPrice: "$0.00",
    productLink: "",
  });
  const [checked, setChecked] = useState(false);
  const handleChangeSwitch = (checked) => {
    setChecked(checked);
  };
  const brandState = useBrandState();
  const { uploadFile, isLoading: isS3Loading } = useS3Uploader();

  useEffect(() => {
    console.log('brandId:' + brandId);
    if (brandId && brandId !== null) {
      BrandService.FetchSingleBrand(brandId);
    }
  }, [brandState.updateNeed.value]);
  const res = brandState.brand.value;

  console.log('res', res);
  //converting the currency to cents
  const convertToCents = (price) => {
    const priceWithoutDollarSign = price.replace("$", "");
    const priceAsNumber = parseFloat(priceWithoutDollarSign);
    const priceInCents = priceAsNumber * 100;
    return Math.round(priceInCents); // Round to nearest cent
  };

  const data = {
    name: "",
    uid: "",
    description: "",
    thumbnail: "",
  };
  const handleChange = (event, maskedvalue, floatvalue) => {
    setProductData({
      ...productData,
      retailPrice: maskedvalue,
    });
  };
  const handleDiscountChange = (event, maskedvalue, floatvalue) => {
    setProductData({
      ...productData,
      discountedPrice: maskedvalue,
    });
  };
  const convertCurrencyHandle = (currency) => {
    let str = currency.substring(1);
    return parseFloat(str.replace(/,/g, "")) * 100;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setErrors({});
    const formValues = {
      name: name.current.value,
      description: description.current.value,
      image: image.current.value,
    };

    const validationErrors = validateFormFields(formValues, fieldErrors);
    if (Object.keys(validationErrors).length) {
      return setErrors(validationErrors);
    }

    productState.merge({ loading: true });
    let url;
    if (image) {
      const response = await uploadFile(image.current.files[0]);

      url = response.Location;
    }
    let retailPrice = convertToCents(productData.retailPrice);
    let discountedPrice = convertToCents(productData.discountedPrice);
    const temp = data;
    temp.name = name.current.value;
    temp.description = description.current.value;
    temp.retailPrice = retailPrice;
    temp.leadTime = 5;
    temp.discountedPrice = discountedPrice;
    temp.linkOut = checked;
    temp.link = productData.productLink;
    temp.thumbnail = url;
    temp.shippingAreas = ["USA"];
    temp.brandName = res.brand_name;
    temp.uid = brandId;
    if (brandId) ProductService.AddProduct(data);
    setProductData({
      ...productData,
      retailPrice: "",
      discountedPrice: "$0.00",
    });
    let mixpanel_product = {
      brand_name: temp.brandName,
      product_name: temp.name,
      product_price: temp.retailPrice,
    };
    Mixpanel.addProduct(mixpanel_product);
    setProductImage("");
    props.setProductName(temp.name);
    props.setActiveTab(2);
  };

  return (
    <div className="mt-4">
      <h1 className="2xl:text-2xl md:text-base font-semibold text-center text-gray-800 mb-4">
        Product Details
      </h1>
      <hr />
      <p className="text-gray-500 2xl:text-base md:text-0.7 text-center my-4">
        Tell us about the product you are promoting.
      </p>

      <form onSubmit={handleSubmit} className="p-4">
        <div className="my-2">
          <label
            htmlFor="productName"
            className="text-black block 2xl:text-base md:text-0.7 mb-2 font-bold"
          >
            Product Name
          </label>
          <input
            name="productName"
            className="mr-2 outline-none bg-gray-100 2xl:p-3 md:p-1 2xl:text-base md:text-0.7  rounded-lg w-full"
            placeholder="Enter Product Name"
            required
            ref={name}
          />
          <div className="text-red px-3 text-0.7 font-medium">
            {errors.name || ""}
          </div>
        </div>
        {/* <div className="my-2"> */}
        <label
          htmlFor="description"
          className="text-black block 2xl:text-base md:text-0.7 mb-2 font-bold hidden"
        >
          Product Description
        </label>
        <input
          name="description"
          className="mr-2 outline-none bg-gray-100 2xl:p-3 md:p-1 2xl:text-base md:text-0.7 rounded-lg w-full"
          value="Removed Data"
          ref={description}
          type="hidden"
        />
        {/* <div className="text-red px-3 font-medium">{errors.description || ''}</div> */}
        {/* </div> */}
        {/*  <div className="my-2">
            <label htmlFor="description" className="text-black block 2xl:text-base md:text-0.7 mb-2 font-bold">
              Link Out
            </label>
            <label>
              <Switch
                onChange={handleChangeSwitch}
                checked={checked}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#ff2642"
                offColor="#999999"
              />
            </label>
          </div>*/}
        <div className="my-2">
          <label
            htmlFor="description"
            className="text-black block 2xl:text-base md:text-0.7 mb-2 font-bold"
          >
            Product Link
          </label>
          <input
            name="productLink"
            className="mr-2 outline-none bg-gray-100 2xl:p-3 md:p-1 2xl:text-base md:text-0.7  rounded-lg w-full"
            placeholder="Enter product link"
            required
            onChange={(e) =>
              setProductData({
                ...productData,
                productLink: e.target.value,
              })
            }
          />
          <div className="text-red px-3 text-0.7 font-medium">
            {errors.productLink || ""}
          </div>
        </div>
        <div className="my-2">
          <label
            htmlFor="RetailPrice"
            className="text-black block 2xl:text-base md:text-0.7 mb-2 font-bold"
          >
            Retail Price
          </label>
          <CurrencyInput
            name="retailPrice"
            prefix="$"
            value={productData.retailPrice}
            onChangeEvent={handleChange}
            className="form-control 2xl:text-base md:text-0.7"
          />
          <div className="text-red px-3 text-0.7 font-medium">
            {errors.retailPrice || ""}
          </div>
        </div>
        {/* <label className="text-black block 2xl:text-base md:text-0.7 mb-2 font-bold">Discounted Price</label>
          <label className="block">
            <CurrencyInput
              name="discountedPrice"
              prefix="$"
              value={productData.discountedPrice}
              onChangeEvent={handleDiscountChange}
              className="form-control 2xl:text-base md:text-0.7"
            />
          </label>*/}
        <div className="m-2">
          <label className="text-black block 2xl:text-base md:text-0.7 my-2 font-bold">
            Product Image
          </label>
          <FileUpload
            title=""
            image={productImage}
            imageRef={image}
            error={errors.image}
            accept="image/jpeg, image/png, image/avi"
            onChange={(event) => {
              if (event.target.files && event.target.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                  setProductImage(e.target.result);
                };
                reader.readAsDataURL(event.target.files[0]);
              }
            }}
          ></FileUpload>
        </div>

        <div className="bg-Blue px-4 text-3xl font-bold py-3 mx-auto rounded-md transform transition-transform duration-500 ease-in-out hover:scale-105 text-white mt-4 w-1/4 items-center justify-center text-center cursor-pointer">
          <CustomButton
            type="submit"
            className="w-full px-4 text-3xl font-extrabold"
            onClick={handleSubmit}
          >
            {productState.loading.value && <Loader />}
            Add Product
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default NewProductOnboarding;
