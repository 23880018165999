import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "../../../Context/AuthContext";
import NewCampaignModal from "./NewCampaignModal";
import {
  CampaignService,
  useCampaignState,
} from "../../../Service/CampaignServices";
import MyCampaignCard from "./MyCampaignCard";
import { BrandContext } from "../../../routes/PrivateRoute/BrandDashboardRoute";
import {
  useCreditPackState,
  CreditPackService,
} from "../../../Service/CreditPackServices";
import { BrandService, useBrandState } from "../../../Service/BrandServices";
import CustomButton from "../../../utils/customButton";
function Campaigns() {
  const campaignState = useCampaignState();
  const [addCampaign, setAddCampaign] = useState();
  const { currentUser } = useAuth();
  const { brandId } = useContext(BrandContext);
  const creditPackState = useCreditPackState();

  useEffect(() => {
    if (brandId) {
      CampaignService.FetchCampaignForBrand(brandId);
    }
  }, [campaignState.updateNeed.value, brandId]);
  useEffect(() => {
    if (brandId) {
      BrandService.FetchSingleBrand(brandId);
      CreditPackService.FetchSubscriptionForUser(brandId);
    }
  }, [creditPackState.updateNeed.value, brandId]);
  const campaign = [];
  if (campaignState.campaigns.value) {
    const res = JSON.stringify(campaignState.campaigns.value);
    Object.keys(JSON.parse(res)).forEach((el) => {
      campaign.push({
        campaignId: el,
        ...JSON.parse(res)[el],
      });
    });
  }
  let creditPack = creditPackState.creditPack.value;
  return (
    <div className="bg-white rounded-lg lg:w-full xs:h-fit">
      <div className="flex items-center h-16 justify-between">
        <h3 className="font-semibold p-8 pl-2 ml-5 2xl:text-2xl md:text-base xs:text-base">
          Crafted Amplify
        </h3>
        {creditPack &&
        creditPack.amplify &&
        creditPack.amplify.status === "active" ? (
          <CustomButton
            className="text-white 2xl:text-base md:text-base 2xl:px-4 2xl:py-2 md:px-2 md:py-1 rounded-lg mr-8"
            onClick={() => setAddCampaign(true)}
          >
            + New Campaign
          </CustomButton>
        ) : (
          <div className="p-2 relative group w-fit flex items-center">
            <span className="bg-Blue text-white 2xl:text-base md:text-base 2xl:px-4 2xl:py-2 md:px-2 md:py-1 rounded-lg mr-8 opacity-50 cursor-not-allowed">
              + New Campaign
            </span>
            <div className="absolute italic text-sm border z-50 border-gray-400 -top-3 left-5 mb-6 transform -translate-x-1/2 translate-y-full w-64 h-auto bg-gray-200 text-gray-800 py-2 px-4 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
              To upgrade to Amplify, please contact{" "}
              <span className="font-bold">sarah@usecrafted.com</span>
            </div>
          </div>
        )}
      </div>
      <p className="text-gray-500 2xl:text-base md:text-0.7 ml-8">
        {" "}
        After you've created a campaign, click below to review & approve all
        creator proposals here.
      </p>
      <div className="bg-white m-4 rounded-lg lg:w-full 2xl:h-30rem md:h-20rem overflow-y-scroll xs:h-fit">
        <div className="grid lg:grid-cols-5 grid-cols-1 z-0 md:grid-cols-2 gap-2 mx-2">
          {campaign.length > 0 &&
            campaign.reverse().map((item, i) => {
              return (
                <>
                  <div key={i}>
                    <MyCampaignCard campaign={item} id={item.campaignId} />
                  </div>
                </>
              );
            })}
        </div>

        <NewCampaignModal
          isOpen={addCampaign}
          closeAction={() => setAddCampaign(false)}
        />
      </div>
    </div>
  );
}

export default Campaigns;
