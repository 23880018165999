import React, { useState } from "react";
import { toast } from "react-hot-toast";
import CustomButton from "../../../utils/customButton";
import {
  RetailCampaignService,
  useRetailCampaignState,
} from "../../../Service/RetailCampaignServices";

function DenyRebateModal({ rebate, onConfirm, onClose }) {
  const retailCampaignState = useRetailCampaignState();
  const [denialReason, setDenialReason] = useState("");

  const handleDeny = () => {
    if (denialReason.trim()) {
      console.log("retail Campaign Id: ", rebate.campaignId);
      console.log("rebate: ", rebate);
      const denialData = {
        enrollee_id: rebate.enrolleeId,
        retail_campaign_id: rebate.campaignId,
        denial_reason: denialReason,
      };

      RetailCampaignService.DenyRebate(denialData);
      onClose();
      toast.success("Rebate denied successfully.");
    } else {
      toast.error("Please enter a reason for denial.");
    }
  };

  if (retailCampaignState.error.value) {
    return (
      <div className="bg-white rounded-md px-10 py-5 w-1/3 flex flex-col items-center justify-center">
        <div className="text-red-500 text-center">
          {retailCampaignState.error.value}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-md px-10 py-5 w-1/3 flex flex-col items-center justify-center">
      <h2 className="text-xl font-semibold mb-4">Deny Rebate</h2>
      <div className="max-h-80 overflow-y-auto">
        <img
          src={rebate.receipt_url}
          alt="Receipt"
          className="mb-4 w-full object-fit h-auto"
        />
      </div>
      <textarea
        placeholder="Enter reason for denial"
        value={denialReason}
        onChange={(e) => setDenialReason(e.target.value)}
        className="border border-gray-300 p-2 rounded-md mb-4 w-full h-24"
      />
      <div className="flex gap-x-4">
        <CustomButton
          onClick={handleDeny}
          className="text-white px-4 py-2 rounded-md bg-red-500"
        >
          Confirm Denial
        </CustomButton>
        <button
          onClick={onClose}
          className="border-2 border-gray-500 text-gray-500 px-4 py-2 rounded-md"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default DenyRebateModal;
