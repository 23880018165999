import { createState, useState } from "@hookstate/core";
import axios from "axios";
import { baseUrl } from "../baseUrl";
import { store, useDispatch } from "../Store";

//State
const state = createState({
  campaigns: [],
  campaign: [],
  retrieving: false,
  fetched: false,
  loading: true,
  updateNeed: false,
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case "CAMPAIGNS_LOADED":
        return s.merge({
          campaigns: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
        });
      case "BRAND_CAMPAIGNS_LOADED":
        return s.merge({
          campaigns: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
        });
      case "CAMPAIGN_DRAFTS_LOADED":
        return s.merge({
          campaign: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
        });
      case "CREATE_CAMPAIGN":
        return s.merge({
          campaign: action.data,
          loading: false,
          updateNeed: true,
        });
      case "CAMPAIGN_UPDATE":
        return s.merge({
          updateNeed: true,
          loading: false,
        });
      case "CAMPAIGN_COMPLETE":
        return s.merge({
          updateNeed: true,
          loading: false,
        });
      case "CAMPAIGN_DELETE":
        return s.merge({
          loading: false,
          updateNeed: true,
        });
      case "CAMPAIGN_LOADED":
        return s.merge({
          campaign: action.data,
          updateNeed: false,
          loading: false,
          fetched: true,
        });
      case "PROPOSAL_APPROVED":
        return s.merge({
          // campaign: action.data,
          updateNeed: true,
          loading: false,
          fetched: true,
        });

      case "PROPOSAL_DENIED":
        return s.merge({
          // campaign: action.data,
          updateNeed: true,
          loading: false,
          fetched: true,
        });

      case "PRODUCT_SHIPPED":
        return s.merge({
          // campaign: action.data,
          updateNeed: true,
          loading: false,
          fetched: true,
        });
      case "PROMO_SENT":
        return s.merge({
          // campaign: action.data,
          updateNeed: true,
          loading: false,
          fetched: true,
        });
      case "PRODUCT_REIMBURSEMENT_SENT":
        return s.merge({
          // campaign: action.data,
          updateNeed: true,
          loading: false,
          fetched: true,
        });
      case "DRAFT_APPROVED":
        return s.merge({
          // campaign: action.data,
          updateNeed: true,
          loading: false,
          fetched: true,
        });
      case "POST_ADDED":
        return s.merge({
          // campaign: action.data,
          updateNeed: true,
          loading: false,
          fetched: true,
        });
      case "PRODUCT_REQ_CAMPAIGN":
        return s.merge({
          updateNeed: true,
          loading: false,
          fetched: true,
        });
      case "CAMPAIGN_SEND_PROPOSAL":
        return s.merge({
          updateNeed: true,
          loading: false,
          fetched: true,
        });
      case "CAMPAIGN_SEND_DELIVERABLES":
        return s.merge({
          updateNeed: true,
          loading: false,
          fetched: true,
        });
      case "CAMPAIGN_RESUBMIT_DELIVERABLES":
        return s.merge({
          updateNeed: true,
          loading: false,
          fetched: true,
        });
      case "CAMPAIGN_INFLUENCER_REMOVED":
        return s.merge({
          updateNeed: true,
          loading: false,
          fetched: true,
        });
      case "CAMPAIGN_UNDO_FULFILLMENT":
        return s.merge({
          updateNeed: true,
          loading: false,
          fetched: true,
        });
      case "CAMPAIGN_UNDO_DRAFT_APPROVAL":
        return s.merge({
          updateNeed: true,
          loading: false,
          fetched: true,
        });
      case "CAMPAIGN_EDIT_TASK":
        return s.merge({
          updateNeed: true,
          loading: false,
          fetched: true,
        });
      case "CAMPAIGN_DELETE_TASK":
        return s.merge({
          updateNeed: true,
          loading: false,
          fetched: true,
        });
      case "CAMPAIGN_ADD_TASK":
        return s.merge({
          updateNeed: true,
          loading: false,
          fetched: true,
        });
      case "LOAD_INVITES":
        return s.merge({
          updateNeed: true,
          loading: false,
          fetched: true,
        });
      default:
        return null;
    }
  }, action.type);
});

export const accessCampaignState = () => state;

export const useCampaignState = () => useState(state);

//Service
export const CampaignService = {
  FetchCampaigns: () => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/campaigns`)
      .then((res) => {
        dispatch(CampaignAction.loadCampaigns(res.data.result));
      })
      .catch((err) => {});
  },

  AddTask: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/campaigns/influencer/addTask/${id}`, data)
      .then((res) => {
        dispatch(CampaignAction.addTask(res.data));
      })
      .catch((err) => {});
  },

  FetchCampaignForBrand: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/campaigns/brand/${id}`)
      .then((res) => {
        dispatch(CampaignAction.loadBrandCampaigns(res.data.result));
      })
      .catch((err) => {});
  },

  FetchCampaignDraftsForBrand: async (id) => {
    const dispatch = useDispatch();
    try {
      const response = await axios.get(
        `${baseUrl}api/campaigns/influencer/${id}/drafts`,
      );
      console.log("API response:", response.data);
      dispatch(CampaignAction.loadBrandCampaigns(response.data.result));
    } catch (error) {
      console.error("API error:", error);
    }
  },
  ApproveProposal: (id, data) => {
    const dispatch = useDispatch();
    return axios
      .post(`${baseUrl}api/campaigns/influencer/${id}/approveProposal`, data)
      .then((res) => {
        dispatch(CampaignAction.approveCampProposal(res.data));
        return res;
      });
  },

  DenyProposal: (id, id_2, data) => {
    const dispatch = useDispatch();
    axios
      .post(
        `${baseUrl}api/campaigns/influencer/${id}/denyProposal/${id_2}`,
        data,
      )
      .then((res) => {
        dispatch(CampaignAction.approveCampProposal(res.data));
      })
      .catch((err) => {
        // Handle error
      });
  },

  SendProposalCampTask: async (id, data) => {
    try {
      const dispatch = useDispatch();
      const response = await axios.post(
        `${baseUrl}api/campaigns/influencer/${id}/submitProposal`,
        data,
      );

      dispatch(CampaignAction.sendProposalCampTask(response));
      return response;
    } catch (error) {
      console.error("Failed to submit proposal:", error);
      throw error;
    }
  },
  ShipProduct: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/campaigns/influencer/${id}/markShipped`, data)
      .then((res) => {
        dispatch(CampaignAction.shipProduct(res));
      })
      .catch((err) => {});
  },
  SendPromo: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/campaigns/influencer/${id}/handlePromo`, data)
      .then((res) => {
        dispatch(CampaignAction.sendPromo(res));
      })
      .catch((err) => {});
  },
  SendProductReimbursement: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/campaigns/influencer/${id}/handleStoreLink`, data)
      .then((res) => {
        dispatch(CampaignAction.sendProductReimbursement(res));
      })
      .catch((err) => {});
  },
  SendDeliverables: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/campaigns/influencer/${id}/submitDeliverables`, data)
      .then((res) => {
        dispatch(CampaignAction.sendDeliverables(res));
      })
      .catch((err) => {});
  },
  ResubmitDeliverables: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(
        `${baseUrl}api/campaigns/influencer/${id}/resubmitDeliverables`,
        data,
      )
      .then((res) => {
        dispatch(CampaignAction.resbmitDeliverables(res));
      })
      .catch((err) => {});
  },
  ApproveDraft: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/campaigns/influencer/${id}/approveDraft`, data)
      .then((res) => {
        dispatch(CampaignAction.approveDraft(res.data));
      })
      .catch((err) => {});
  },
  AddPost: (id, data) => {
    const dispatch = useDispatch();
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}api/campaigns/influencer/${id}/addPost`, data)
        .then((res) => {
          if (res.status < 200 || res.status >= 300) {
            throw new Error(res.data.error);
          }
          dispatch(CampaignAction.addPost(res.data));
          console.log(res.data); // Log the response data
          resolve(res.data);
        })
        .catch((err) => {
          console.error("ERROR", err);
          reject(err);
        });
    });
  },

  AddCampaign: async (data, brandId) => {
    const dispatch = useDispatch();
    try {
      const response = await axios.post(
        `${baseUrl}api/campaigns/influencer/create/${brandId}`,
        data,
      );
      const campaign = {
        ...response.data,
        campaign_id: response.data.campaignKey,
      };

      dispatch(CampaignAction.createCampaign(campaign));

      return campaign;
    } catch (err) {
      console.log(err);
      throw err; // Re-throw the error to propagate it to the caller
    }
  },

  UpdateCampaign: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/campaigns/influencer/${id}/editCampaign`, data)
      .then((res) => {
        dispatch(CampaignAction.updateCampaign(res));
      })
      .catch((err) => {});
  },

  DeleteCampaign: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/campaigns/cancelCampaign/${id}`, data)
      .then((res) => {
        dispatch(CampaignAction.deleteCampaign(res));
      })
      .catch((err) => {});
  },

  FetchSingleCampaign: (id) => {
    const dispatch = useDispatch();
    return axios
      .get(`${baseUrl}api/campaigns/${id}`)
      .then((res) => {
        dispatch(CampaignAction.loadSingleCampaign(res.data.result));
        return res.data.result;
      })
      .catch((err) => {
        console.log(err);
        throw err; // Re-throw the error to propagate it to the caller
      });
  },

  CompleteCampaign: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/campaigns/${id}/complete`, data)
      .then((res) => {
        dispatch(CampaignAction.completeCampaign(res));
      })
      .catch((err) => {});
  },

  RemoveInfluencer: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/campaigns/influencer/${id}/removeInfluencer`, data)
      .then((res) => {
        dispatch(CampaignAction.removeInfluencer(res));
      })
      .catch((err) => {});
  },

  UndoFulfillment: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/campaigns/influencer/${id}/undoFulfillment`, data)
      .then((res) => {
        dispatch(CampaignAction.undoFulfillment(res));
      })
      .catch((err) => {});
  },

  UndoDraftApproval: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/campaigns/influencer/${id}/undoDraftApproval`, data)
      .then((res) => {
        dispatch(CampaignAction.undoDraftApproval(res));
      })
      .catch((err) => {});
  },

  EditTask: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/campaigns/influencer/${id}/editTask`, data)
      .then((res) => {
        dispatch(CampaignAction.editTask(res));
      })
      .catch((err) => {});
  },

  EditCampaign: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/campaigns/influencer/${id}/editCampaign`, data)
      .then((res) => {
        dispatch(CampaignAction.editCampaign(res));
      })
      .catch((err) => {});
  },

  deleteTask: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/campaigns/influencer/${id}/deleteTask`, data)
      .then((res) => {
        dispatch(CampaignAction.deleteTask(res));
      })
      .catch((err) => {});
  },

  getCampaignInvitesAnalytics: (id) => {
    const dispatch = useDispatch();
    return axios
      .get(`${baseUrl}api/campaigns/${id}/getInviteAnalytics`)
      .then((res) => {
        dispatch(CampaignAction.loadInvites(res.data.result));
        return res.data.result;
      })
      .catch((err) => {
        console.log(err);
        throw err; // Re-throw the error to propagate it to the caller
      });
  },
};

//Action
export const CampaignAction = {
  loadCampaigns: (data) => {
    return {
      type: "CAMPAIGNS_LOADED",
      data: data,
    };
  },
  addTask: (data) => {
    return {
      type: "CAMPAIGN_ADD_TASK",
      data: data,
    };
  },

  loadBrandCampaigns: (data) => {
    return {
      type: "BRAND_CAMPAIGNS_LOADED",
      data: data,
    };
  },
  sendProposalCampTask: (data) => {
    return {
      type: "CAMPAIGN_SEND_PROPOSAL",
      data: data,
    };
  },
  sendDeliverables: (data) => {
    return {
      type: "CAMPAIGN_SEND_DELIVERABLES",
      data: data,
    };
  },
  resubmitDeliverables: (data) => {
    return {
      type: "CAMPAIGN_RESUBMIT_DELIVERABLES",
      data: data,
    };
  },
  fetchCampaignDraftsForBrand: (data) => {
    return {
      type: "CAMPAIGN_DRAFTS_LOADED",
      data: data,
    };
  },
  approveCampProposal: (data) => {
    return {
      type: "PROPOSAL_APPROVED",
      data: data,
    };
  },

  denyCampProposal: (data) => {
    return {
      type: "PROPOSAL_DENIED",
      data: data,
    };
  },

  shipProduct: (data) => {
    return {
      type: "PRODUCT_SHIPPED",
      data: data,
    };
  },
  sendPromo: (data) => {
    return {
      type: "PROMO_SENT",
      data: data,
    };
  },
  sendProductReimbursement: (data) => {
    return {
      type: "PRODUCT_REIMBURSEMENT_SENT",
      data: data,
    };
  },
  approveDraft: (data) => {
    return {
      type: "DRAFT_APPROVED",
      data: data,
    };
  },
  addPost: (data) => {
    return {
      type: "POST_ADDED",
      data: data,
    };
  },
  loadSingleCampaign: (data) => {
    return {
      type: "CAMPAIGN_LOADED",
      data: data,
    };
  },
  createCampaign: (data) => {
    return {
      type: "CREATE_CAMPAIGN",
      data: data,
    };
  },
  updateCampaign: (data) => {
    return {
      type: "CAMPAIGN_UPDATE",
      data: data,
    };
  },
  completeCampaign: (data) => {
    return {
      type: "CAMPAIGN_COMPLETE",
      data: data,
    };
  },
  deleteCampaign: (data) => {
    return {
      type: "CAMPAIGN_DELETE",
      data: data,
    };
  },
  removeInfluencer: (data) => {
    return {
      type: "CAMPAIGN_INFLUENCER_REMOVED",
      data: data,
    };
  },
  undoFulfillment: (data) => {
    return {
      type: "CAMPAIGN_UNDO_FULFILLMENT",
      data: data,
    };
  },
  undoDraftApproval: (data) => {
    return {
      type: "CAMPAIGN_UNDO_DRAFT_APPROVAL",
      data: data,
    };
  },
  editTask: (data) => {
    return {
      type: "CAMPAIGN_EDIT_TASK",
      data: data,
    };
  },

  editCampaign: (data) => {
    return {
      type: "CAMPAIGN_UPDATE",
      data: data,
    };
  },

  deleteTask: (data) => {
    return {
      type: "CAMPAIGN_DELETE_TASK",
      data: data,
    };
  },

  loadInvites: (data) => {
    return {
      type: "LOAD_INVITES",
      data: data,
    };
  },
};
