import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import "react-responsive-modal/styles.css";
import {
  AuthService,
  userAuthstate,
} from "../../../Service/authenticationService";
import { useTaskState, TaskService } from "../../../Service/TaskServices";
import { useBrandState, BrandService } from "../../../Service/BrandServices";
import {
  useRetailCampaignState,
  RetailCampaignService,
} from "../../../Service/RetailCampaignServices";
import {
  SubscriptionService,
  useSubscriptionState,
} from "../../../Service/SubscriptionServices";
import { Link } from "react-router-dom";
import {
  useProductState,
  ProductService,
} from "../../../Service/ProductServices";
import { BrandContext } from "../../../routes/PrivateRoute/BrandDashboardRoute";
import Modal from "react-responsive-modal";

const NewRetailCampaignModal = forwardRef((props, ref) => {
  // const { currentUser, currentBrand } = useAuth();
  const taskState = useTaskState();
  const subsState = useSubscriptionState();
  const retailCampaignState = useRetailCampaignState();
  const brandState = useBrandState();
  const productState = useProductState();
  const [raw, setRaw] = useState(false);
  const [error, setError] = useState("");
  const [contentUsage, setContentUsage] = useState("");
  const [isRecipesChecked, setIsRecipesChecked] = useState(false);
  const [divs, setDivs] = useState([]);
  const [selectedColor, setSelectedColor] = useState("#ffffff"); // Default to white
  const [couponType, setCouponType] = useState(""); // Coupon type state
  const [dollarOff, setDollarOff] = useState(0);
  const [percentageOff, setPercentageOff] = useState(0);
  const [selectedObjective, setSelectedObjective] = useState("");
  const [redemptionBudget, setRedemptionBudget] = useState(0);
  // const [updateNeeded, setUpdateNeeded] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [storeLocator, setStoreLocator] = useState("");
  const [retailer, setRetailer] = useState("");
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [taskCredits, setTaskCredits] = useState(0);
  const [platformsSelected, setPlatformsSelected] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [showBrief, setShowBrief] = useState(false);
  const [selectedBriefData, setSelectedBriefData] = useState({});
  //const [generateBrief, setGenerateBrief] = useState(false);
  const { brandId } = useContext(BrandContext);

  const openModal = (briefData) => {
    setSelectedBriefData(briefData); // Set the selected brief data
    setShowBrief(true); // Show the modal
  };
  const fieldErrors = {
    name: "Please enter brief name",
    note1: "Please enter the products do they want to showcase ",
    note2: "Please enter their target demographic",
    note3: "Please enter other talking points",
  };

  const name = useRef("");
  const note1 = useRef("");
  const note2 = useRef("");
  const note3 = useRef("");
  const briefLink = useRef("");
  const regions = useRef([]);
  const authState = userAuthstate();

  function resetForm() {
    setSelectedProduct([]);
  }

  useImperativeHandle(ref, () => ({
    resetForm,
  }));

  useEffect(() => {
    if (brandId !== null) {
      BrandService.FetchSingleBrand(brandId);
      SubscriptionService.FetchSubscriptionForUser(brandId);
    }
  }, [brandState.updateNeed.value]);
  useEffect(() => {
    // tabState.merge({ currentTab: 1 });
    if (productState.updateNeed.value) {
      ProductService.FetchProductForUser(brandId);
    }
  }, [productState.updateNeed.value]);

  const res = JSON.stringify(productState.products.value);

  const productTemp = [];
  if (productState.products.value) {
    Object.keys(JSON.parse(res)).forEach((el) => {
      productTemp.push({
        id: el,
        ...JSON.parse(res)[el],
      });
    });
  }
  const isAtLeastOneCheckboxChecked = () => {
    return (
      divs &&
      divs.length > 0 &&
      divs.some(
        (variation) =>
          variation.voiceover ||
          variation.music ||
          variation.onScreenText ||
          variation.subtitles,
      )
    );
  };
  //console.log("divs", divs);
  const brand = brandState.brand.value;
  //console.log("brand info:", brand);
  const subs = subsState.subscription.value;
  let availUsage = 0;
  if (subs) {
    availUsage = subs.paidUsage - subs.currentUsage;
  }
  useEffect(() => {
    if (brand && subs) {
      availUsage = subs.paidUsage - subs.currentUsage;
    }
  }, [subs, subsState.updateNeed.value]);

  const [errors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    const product = productTemp.find((obj) => obj.id === selectedProduct);
    try {
      event.preventDefault();
      const temp = {};

      // temp. =
      await TaskService.AddTask(temp);
    } catch (error) {
      console.error(error);
    }
  };

  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
  };

  const handleCouponTypeChange = (event) => {
    console.log("coupon_type: " + event.target.value);
    setCouponType(event.target.value);
    setDollarOff(0);
    setPercentageOff(0);
  };

  const handleDollarOffChange = (event) => {
    setDollarOff(event.target.value);
  };

  const handlePercentageOffChange = (event) => {
    setPercentageOff(event.target.value);
  };

  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    setStartDate(selectedStartDate);

    if (endDate && selectedStartDate > endDate) {
      setEndDate("");
    }
  };

  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;
    if (selectedEndDate >= startDate) {
      setEndDate(selectedEndDate);
    }
  };

  const handleStoreLocatorChange = (event) => {
    setStoreLocator(event.target.value);
  };

  const handleRetailerChange = (event) => {
    setRetailer(event.target.value);
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={() => {
        props.closeAction();
      }}
      center
      closeOnOverlayClick={false}
    >
      <div>
        <div className="mt-4">
          <div className="mb-4">
            <label className="text-black font-bold md:text-lg mb-2">
              Retail Campaign Coupon Type
            </label>
            <div className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full">
              <select
                className="outline-none bg-gray-100 w-full"
                value={couponType}
                onChange={handleCouponTypeChange}
              >
                <option value="">---Select Coupon Type---</option>
                <option value="percentage_off">Percentage Off</option>
                <option value="dollar_off">Dollar Off</option>
                <option value="bogo">BOGO</option>
                <option value="free">Free</option>
              </select>
            </div>
          </div>

          {couponType === "percentage_off" && (
            <div className="mb-4">
              <label className="text-black font-bold md:text-lg mb-2">
                Percentage Off
              </label>
              <input
                className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                placeholder="Enter Percentage Off"
                type="number"
                min="1"
                max="100"
                value={percentageOff}
                onChange={handlePercentageOffChange}
              />
            </div>
          )}

          {couponType === "dollar_off" && (
            <div className="mb-4">
              <label className="text-black font-bold md:text-lg mb-2">
                Dollar Off
              </label>
              <div className="flex">
                $
                <input
                  className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                  placeholder="Enter Dollar Off"
                  type="number"
                  min="0"
                  value={dollarOff}
                  onChange={handleDollarOffChange}
                />
              </div>
            </div>
          )}

          <div className="mb-4">
            <label className="text-black font-bold md:text-lg mb-2">
              Store Locator
            </label>
            <input
              className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
              placeholder="Enter Store Locator URL"
              type="url"
              value={storeLocator}
              onChange={handleStoreLocatorChange}
            />
          </div>

          <div className="mb-4">
            <label className="text-black font-bold md:text-lg mb-2">
              Retailer
            </label>
            <div className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full">
              <select
                className="outline-none bg-gray-100 w-full"
                value={retailer}
                onChange={handleRetailerChange}
              >
                <option value="">---Select Retailer---</option>
                <option value="walmart">Walmart</option>
                <option value="target">Target</option>
                <option value="whole_foods">Whole Foods</option>
                <option value="kroger">Kroger</option>
                <option value="costco">Costco</option>
                <option value="publix">Publix</option>
                <option value="albertsons">Albertsons</option>
                <option value="sams_club">Sam’s Club</option>
                <option value="wegmans">Wegmans</option>
                <option value="heb">H-E-B</option>
                <option value="trader_joes">Trader Joe’s</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>

          <div className="my-2">
            <label
              htmlFor="audience"
              className="text-black font-bold block md:text-lg mb-2"
            >
              Showcased Product
            </label>
            <p className="text-gray-500 text-base mb-1">
              Please choose the product you want creators to showcase, there is
              only 1 showcased product per brief.
            </p>
            <select
              // ref={selectRef}
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
              className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
              required
              placeholder="Choose Product..."
            >
              <option className="text-sm text-indigo-800" value="">
                Choose Product
              </option>
              {productTemp.map((item) => {
                return (
                  <option
                    className="text-sm text-indigo-800"
                    value={item.id}
                    key={item.id}
                  >
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="mb-4">
            <label className="text-black font-bold md:text-lg mb-2">
              Start - End Date
            </label>
            <input
              type="date"
              className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
              placeholder="Select Start Date"
              value={startDate}
              onChange={handleStartDateChange}
            />
            <input
              type="date"
              className="mt-2 mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
              placeholder="Select End Date"
              value={endDate}
              onChange={handleEndDateChange}
              disabled={!startDate}
            />
          </div>

          <div className="mb-4">
            <label className="text-black font-bold md:text-lg mb-2">
              Theme Color
            </label>
            <div className="flex items-center">
              <input
                type="color"
                className="mr-2 outline-none bg-gray-100 p-3 rounded-lg cursor-pointer"
                value={selectedColor}
                onChange={handleColorChange}
                style={{ display: "none" }} // Hide the actual color input
                id="colorPicker"
              />
              <input
                type="text"
                className="outline-none bg-gray-100 p-3 rounded-lg w-24 text-center"
                value={selectedColor}
                readOnly
              />
              <div
                className="ml-2 h-8 w-8 rounded-lg cursor-pointer"
                style={{
                  backgroundColor: selectedColor,
                  border: "1px solid #000",
                }}
                onClick={() => document.getElementById("colorPicker").click()} // Trigger the color input's click
              ></div>
            </div>
          </div>
          <div className="mb-4">
            <label className="text-black font-bold md:text-lg mb-2">
              Redemption Budget
            </label>
            <p className="text-gray-500 text-sm mb-1"></p>
            <div className="flex">
              $
              <input
                type="number"
                className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                placeholder="Redemption Budget"
                value={redemptionBudget}
                onChange={(e) => setRedemptionBudget(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default NewRetailCampaignModal;
