import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  brands: [],
  brand: [],
  brandOrder: [],
  brandUser: [],
  retrieving: false,
  fetched: false,
  inserted: false,
  message: '',
  cashBalance: '',
  loading: true,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'BRANDS_LOADED':
        return s.merge({
          brands: action.data,
          updateNeed: true,
          loading: false,
          retrieving: true
        });
      case 'CREATE_BRAND':
        return s.merge({
          brand: action.data,
          loading: false,
          updateNeed: true
        });
      case 'CREATE_TRIAL_BRAND':
        return s.merge({
          brand: action.data,
          loading: false,
          updateNeed: true
        })
      case 'CRATE_BRAND_ADMIN':
        return s.merge({
          brand: action.data,
          loading: false,
          updateNeed: true
        });
      case 'BRAND_UPDATE':
        return s.merge({
          updateNeed: true,
          loading: false
        });
      case 'BRAND_DELETE':
        return s.merge({
          loading: false,
          updateNeed: true
        });
      case 'BRAND_CLEAR':
        return s.merge({
          inserted: false
        });
      case 'BRAND_LOADED':
        return s.merge({
          brand: action.data,
          updateNeed: false,
          loading: false,
          fetched: true
        });
      
      case 'ACCOUNT_INFO_LOADED':
        return s.merge({
          brand: action.data,
          updateNeed: false,
          loading: false,
          fetched: true
        })
      case 'BRAND_INSERTED':
        return s.merge({
          message: action.data,
          updateNeed: false,
          loading: false,
          fetched: true,
          inserted: true
        });
      case 'CHECK_CASH_BALANCE':
        return s.merge({
          cashBalance: action.data,
          updateNeed: false,
          loading: false,
          fetched: true
        });
      case 'BRAND_ORDERS':
        return s.merge({
          brandOrder: action.data,
          updateNeed: false,
          loading: false,
          fetched: true
        });
      default:
        return null;
    }
  }, action.type);
});

export const accessBrandState = () => state;

export const useBrandState = () => useState(state);
//Service
export const BrandService = {
  FetchBrandForUser: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/brands/${id}`)
      .then((res) => {
        dispatch(BrandAction.loadSingleBrand(res.data.result));
      })
      .catch((err) => {});
  },

  FetchAccountInfo: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/accounts/getAccountInfo/${id}`)
      .then((res) => {
        dispatch(BrandAction.loadAccountInfo(res.data.result));
      })
  },

  FetchBrands: () => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/brands`)
      .then((res) => {
        dispatch(BrandAction.loadBrands(res.data.result));
      })
      .catch((err) => {});
  },
  AddBrand: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/brands/createBrand`, data)
      .then((res) => {
        dispatch(BrandAction.createBrand(res));
        if (res.data.statuscode) {
          dispatch(BrandAction.insertBrand(res.data.message));
        }
      })
      .catch((err) => {});
  },
  AddTrialBrand: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/brands/createTrialBrand`, data)
      .then((res) => {
        dispatch(BrandAction.createTrialBrand(res));
        if (res.data.statuscode) {
          dispatch(BrandAction.insertTrialBrand(res.data.message));
        }
      })
      .catch((err) => {});
  },
  ClearBrand: async () => {
    const dispatch = useDispatch();
    dispatch(BrandAction.clearBrand(false));
  },

  UpdateBrand: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/brands/updateBrand/${id}`, data)
      .then((res) => {
        dispatch(BrandAction.updateBrand(res));
      })
      .catch((err) => {});
  },
  DeleteBrand: (id, data) => {
    const dispatch = useDispatch();
    axios
      .delete(`${baseUrl}api/brands/deletebrand/${id}`, { data: data })
      .then((res) => {
        dispatch(BrandAction.deleteBrand(res));
      })
      .catch((err) => {});
  },
  FetchSingleBrand: (id) => {
    const dispatch = useDispatch();
  
    return axios
      .get(`${baseUrl}api/brands/${id}`)
      .then((res) => {
        dispatch(BrandAction.loadSingleBrand(res.data.result));
        //return res.data.result;  // Return the result data
      })
      .catch((err) => {
        throw err;  // Re-throw the error to be caught by the caller
      });
  },
  FetchCashBalance: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/brands/checkCashBalance/${id}`)
      .then((res) => {
        dispatch(BrandAction.checkCashBalance(res.data.result.balance));
      })
      .catch((err) => {});
  },

  FetchBrandOrders: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/orders/getBrandOrders/${id}`)
      .then((res) => {
        dispatch(BrandAction.loadBrandOrders(res.data.result));
      })
      .catch((err) => {});
  },
  FetchCustomerOrders: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/orders/getCustomerOrders/${id}`)
      .then((res) => {
        dispatch(BrandAction.loadBrandOrders(res.data.result));
      })
      .catch((err) => {});
  },

  CreateBrandFromAdmin: (data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/brands/createBrandFromAdmin`, data)
      .then((res) => {
        dispatch(BrandAction.createBrand(res));
        if (res.data.statuscode) {
          dispatch(BrandAction.insertBrand(res.data.message));
        }
      })
      .catch((err) => {});
  }
};

//Action
export const BrandAction = {
  loadUserBrands: (data) => {
    return {
      type: 'BRANDS_LOADED',
      data: data
    };
  },
  loadBrands: (data) => {
    return {
      type: 'BRANDS_LOADED',
      data: data
    };
  },
  loadAccountInfo: (data) => {
    return {
      type: 'ACCOUNT_INFO_LOADED',
      data: data
    }
  },

  loadSingleBrand: (data) => {
    return {
      type: 'BRAND_LOADED',
      data: data
    };
  },
  clearBrand: (data) => {
    return {
      type: 'BRAND_CLEAR',
      fetched: data
    };
  },
  insertBrand: (data) => {
    return {
      type: 'BRAND_INSERTED',
      data: data
    };
  },
  createBrand: (data) => {
    return {
      type: 'CREATE_BRAND',
      data: data
    };
  },
  createTrialBrand: (data) => {
    return {
      type: 'CREATE_TRIAL_BRAND',
      data: data
    };
  },
  insertTrialBrand: (data) => {
    return {
      type: 'TRIAL_BRAND_INSERTED',
      data: data
    };
  },
  updateBrand: (data) => {
    return {
      type: 'BRAND_UPDATE',
      data: data
    };
  },
  deleteBrand: (data) => {
    return {
      type: 'BRAND_DELETE',
      data: data
    };
  },
  checkCashBalance: (data) => {
    return {
      type: 'CHECK_CASH_BALANCE',
      data: data
    };
  },
  loadBrandOrders: (data) => {
    return {
      type: 'BRAND_ORDERS',
      data: data
    };
  },
  createBrandFromAdmin: (data) => {
    return {
      type: 'CRATE_BRAND_ADMIN',
      data: data
    };
  }
};
