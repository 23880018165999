import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import CustomButton from "../../utils/customButton";
import { useAuth } from "../../Context/AuthContext";
import { Toaster, toast } from "react-hot-toast";
import { IoIosInformationCircleOutline } from "react-icons/io";

const ReauthenticatePhoneNumber = (props) => {
  const [phone, setPhone] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const [confirmationObject, setConfirmationObject] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const { sendCode, VerifyCode } = useAuth(); // Firebase auth functions

  const sendOTP = async () => {
    setSubmitted(true);
    let formattedPhone = `+${phone}`;

    try {
      console.log("Formatted Phone: ", formattedPhone);

      // Send OTP
      const confirmationResult = await sendCode(formattedPhone);
      setConfirmationObject(confirmationResult);
      setCodeSent(true);

    // Check if 'confirm' function exists
    if (typeof confirmationResult.confirm === "function") {
        console.log("confirm() exists and is a function.");
        } else {
        console.log("confirm() does not exist or is not a function.");
        }
        
      console.log("OTP sent successfully");

      setSubmitted(false);
    } catch (error) {
      console.error("Error sending OTP:", error);
      // Handle specific Firebase errors
        switch (error.code) {
        case "auth/invalid-phone-number":
        toast.error(
            "Invalid phone number. Please check the number and try again.",
        );
        break;
        case "auth/quota-exceeded":
            toast.error("SMS quota exceeded. Please try again later.");
            break;
        case "auth/user-disabled":
            toast.error("User account is disabled.");
            break;
        default:
        toast.error("Failed to send OTP. Please try again.");
    }

    setSubmitted(false);
    }
  };
  

  const verifyOTP = async () => {
    setSubmitted(true);

    try {

    const user = await VerifyCode(confirmationObject, otpCode);
      //await VerifyCode(confirmationObject, otpCode);
      console.log("Re-authentication successful: " + user.uid);

      setCodeSent(false);
      toast.success("Phone number verified successfully!");

      // Move to the next step
      props.setCurrentTab(1);
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Invalid OTP. Please try again.");
    }

    setSubmitted(false);
  };

  return (
    <div className="flex flex-col items-center justify-center p-5 rounded-md w-full max-w-sm mx-auto">
      <Toaster />
        <h1 className="text-2xl font-bold">Re-authenticate via Phone Number</h1>
        <p className="text-gray-500 text-center">After re-authentication, you will be redirected to receipt upload.</p>
        <div id="recaptcha-container"></div>
      {/* Phone Number Input */}
      {!codeSent ? (
        <div className="mt-4 w-full">
          <PhoneInput
            country={"us"}
            onlyCountries={["us", "ca"]}
            value={phone}
            onChange={setPhone}
            placeholder="Enter your phone number"
            className="focus:outline-none w-fit md:text-base xs:text-0.7 bg-white text-black"
          />
          <CustomButton
            onClick={() => sendOTP()}
            className="w-full bg-blue-600 text-white font-bold py-3 px-8 mt-5 rounded-md hover:bg-blue-700 disabled:opacity-50"
            disabled={submitted || phone.length === 0}
          >
            {submitted ? "Sending..." : "Send Code"}
          </CustomButton>
            {/* Information Box */} 
          <div className="relative bg-gray-100 p-4 rounded-lg m-6 shadow-md">
                <IoIosInformationCircleOutline className="absolute top-4 left-2" size={15} />
                <p className="ml-4 text-gray-800 leading-relaxed md:text-0.75 xs:text-0.7">
                Your <strong>phone number</strong> is used solely for authentication, and we will 
                never contact you beyond sending a verification code.
                </p>
          </div>
        </div>
      ) : (
        // OTP Verification Input
        <div className="mt-4 w-full">
          <p className="text-center mb-4">Enter the verification code we sent to your phone via text.</p>
          <input
            type="text"
            placeholder="Enter Code"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
            className="focus:outline-none w-full md:text-base xs:text-0.7 bg-white text-black p-3 rounded-md"
            maxLength={6}
          />
          <CustomButton
            onClick={verifyOTP}
            className="w-full bg-blue-600 text-white font-bold py-3 px-8 mt-5 rounded-md hover:bg-blue-700 disabled:opacity-50"
            disabled={submitted || otpCode.length !== 6}
          >
            {submitted ? "Verifying..." : "Verify Code"}
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default ReauthenticatePhoneNumber;