import React, { useEffect, useState } from 'react';
import MyInfluencerTaskCard from './MyInfluencerTaskCard';
import { baseUrl } from '../../baseUrl';
import { useAuth } from '../../Context/AuthContext';
import Loader2 from '../Loader2';

function InfluencerTasks() {
  const [influencerTasks, setInfluencerTasks] = useState([]);
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [isFunctionCompleted, setIsFunctionCompleted] = useState(false); // Step 1


  const fetchInfluencerTasks = async () => {
    try {
      const response = await fetch(`${baseUrl}api/influencers/${currentUser.uid}/tasks`);
      const data = await response.json();
      setInfluencerTasks(data.result);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching Influencer Tasks:', error);
    }
  };


  useEffect(() => {
    fetchInfluencerTasks();
  }, []);

  const influencerTasksArray = influencerTasks && Object.entries(influencerTasks).map((e) => ({ id: e[0], ...e[1] }));

  return (
    <div className="bg-white m-4 rounded-lg overflow-y-scroll lg:w-full xs:h-fit">
    <div style={{ position: 'sticky', top: 0, zIndex: 1 }}>
      <h3 className="font-semibold p-4 md:mb-1 bg-white w-full h-10 2xl:text-2xl md:text-base xs:text-sm">My Influencer Briefs</h3>
      <h3 className=' text-gray-500 2xl:text-base md:text-0.7 xs:text-xs ml-4 mb-2 '>For these briefs you will be asked not only to generate the video assets, but also post to each of the platforms you've connected in the 'Settings' tab.</h3>
    {loading ? (
       <div className=" md:h-21" style={{maxHeight: '40vh'}}>
      <Loader2/>
      </div>
    ) : (
      <div className="bg-white rounded-lg overflow-y-scroll  xs:h-fit">
        <div style={{ position: 'sticky', top: 0, zIndex: 1 }}>
        </div>
        <div className=" md:h-21" style={{maxHeight: '50vh'}}>
          {(!influencerTasksArray || influencerTasksArray.length === 0 || 
            influencerTasksArray.filter(item => item.status !== 'denied').length === 0) && (
            <>
              <div className="md:text-4xl flex mt-10 font-bold items-center justify-center">
                You have no current influencer briefs.
              </div>
              <div className="md:text-4xl text-center text-lg flex mt-10 font-bold items-center justify-center">
                Influencer briefs will appear here once you have applied for one.
              </div>
            </>
          )}
          <div className="grid lg:grid-cols-5 grid-cols-1 md:grid-cols-2 gap-2 ">
          {influencerTasksArray &&
            influencerTasksArray
              .filter((item) => item.status !== 'denied')
              .sort((a, b) => {
                const statusWeights = {
                  'submitted': 6,
                  'approved': 5,
                  'revision': 4,
                  'drafted': 3,
                  'pending': 2,
                  'completed': 1
                };
                return statusWeights[b.status] - statusWeights[a.status];
              })
              .map((item, i) => {
                const id = item.id; // Extract id from the key
                return (
                  <div key={i}>
                    <MyInfluencerTaskCard task={item} id={id} fetchInfluencerTasks={fetchInfluencerTasks} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    )}
      </div>
    </div>
  );
}

export default InfluencerTasks;