import React, { useState } from "react";
import { toast } from "react-hot-toast";
import CustomButton from "../../../utils/customButton";
import Loader from "../../Loader";
import Modal from "react-responsive-modal";
import {
  RetailCampaignService,
  useRetailCampaignState,
} from "../../../Service/RetailCampaignServices";

function ApproveRebateModal({ rebate, onConfirm, onClose }) {
  const retailCampaignState = useRetailCampaignState();
  const [price, setPrice] = useState("");
  const [calculatedRebate, setCalculatedRebate] = useState(0);
  const [isCalculating, setIsCalculating] = useState(false);

  const calculateRebate = () => {
    const discount = rebate.rebate_promo || 0;

    let rebateAmount = 0;

    if (rebate.couponType == "percent_off") {
      rebateAmount = price * rebate.percentageOff;
    } else if (rebate.couponType == "dollar_off") {
      rebateAmount = price - rebate.dollarOff;
    } else if (rebate.couponType == "bogo") {
      rebateAmount = price;
    } else if (rebate.couponType == "free") {
      rebateAmount = price;
    }

    setCalculatedRebate(rebateAmount.toFixed(2));
    setIsCalculating(false);
  };

  const handleConfirm = () => {
    if (calculatedRebate !== null) {
      const approvalData = {
        enrollee_id: rebate.enrolleeId,
        retail_campaign_id: rebate.campaignId,
        payout_amount: parseInt(calculatedRebate * 100),
      };
      RetailCampaignService.ApproveRebate(approvalData);
      onClose();
      toast.success("Rebate approved successfully.");
    } else {
      toast.error("Please calculate the rebate before confirming.");
    }
  };

  if (retailCampaignState.error.value) {
    return (
      <div className="bg-white rounded-md overflow-y-auto px-10 py-5 w-1/3 max-h-full my-20 flex flex-col items-center justify-center">
        <div className="text-red-500 text-center">
          {retailCampaignState.error.value}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-md overflow-y-auto px-10 py-5 w-1/3 max-h-full my-20 flex flex-col items-center justify-center">
      <h2 className="text-xl font-semibold mb-4">Approve Rebate</h2>
      <div className="max-h-80 overflow-y-auto">
        <img
          src={rebate.receipt_url}
          alt="Receipt"
          className="mb-4 w-full object-fit h-auto"
        />
      </div>
      <p className="mb-2">
        {rebate &&
          rebate.couponType === "dollar_off" &&
          `$${rebate.dollarOff} off`}
        {rebate &&
          rebate.couponType === "percent_off" &&
          `${rebate.percentOff}% off`}
        {rebate && rebate.couponType === "bogo" && `Buy 1, Get 1 Free`}
        {rebate && rebate.couponType === "free" && `Free`}
      </p>

      <input
        type="number"
        placeholder="Enter price of the item"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        className="border border-gray-300 p-2 rounded-md mb-4 w-full"
      />
      {calculatedRebate !== 0 && (
        <p className="mb-4">Calculated Rebate: ${calculatedRebate}</p>
      )}
      <div className="flex gap-x-4">
        <CustomButton
          onClick={calculateRebate}
          className="text-white px-4 py-2 rounded-md bg-blue-500"
        >
          {isCalculating ? <Loader /> : "Calculate Rebate"}
        </CustomButton>
        <CustomButton
          onClick={handleConfirm}
          className="text-white px-4 py-2 rounded-md bg-green-500"
        >
          Confirm Rebate
        </CustomButton>
        <button
          onClick={onClose}
          className="border-2 border-gray-500 text-gray-500 px-4 py-2 rounded-md"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default ApproveRebateModal;
