import React,{useEffect} from 'react';
import NavBar from '../../Components/Navbar';
import { useAuth } from '../../Context/AuthContext';
import Sidebar from '../../Components/Brand_Dashboard/Sidebar';
import { UsersService } from '../../Service/userServices';
import BrandOnboarding from '../Onboarding/Brand';


export default function Brand({ children }) {
  const { currentUser } = useAuth();
    useEffect(() => {
      if (currentUser.uid) {
        UsersService.FetchUserInfo(currentUser.uid);
      }
    }, []);

  return (
    <div>
    
        <div>
          {/* <Onboarding /><BrandOnboarding /> */}
        </div>

      <NavBar />
      <div className="md:flex flex-col z-100 md:flex-row h-screen w-full bg-gray-100">
        <Sidebar />
        {children}
      </div>
    </div>
  );
}
